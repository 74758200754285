footer {
    color: #fff;

    .social-links {
        padding-top: 1.5em;
        margin-left: auto;
        margin-right: auto;

        a {
            width: 36px !important;
            height: 36px !important;

            &:before {
                font-size: 16px !important;
            }
        }
    }

    .footer {
        &-btn-label {
            color: $brown_base;
            font-family: $rubik;
            font-weight: $rubik-medium;
            font-size: 18px;
        }

        &-btn-wrap {
            display: flex;
            align-items: center;
            position: relative;
            z-index: 111;
            top: 15px;
            justify-content: flex-end;

            .btn {
                border-top-right-radius: 0;
                border-top-left-radius: 0;
                width: auto;
            }

            .footer-btn-label {
                margin-right: 30px;
            }

            .btn:nth-child(1), & .btn:nth-child(2) {
                //position: absolute;
            }

            & .btn:nth-child(1) {
                //right: 0;
                margin-right: 30px;
            }
        }

        &-first-row {
            margin-bottom: 50px;

            .main-text {
                font-family: $open-sans;
                font-size: 14px;
                font-weight: normal;
                line-height: 1.5;
            }
        }

        &-text {
            &-left {
                color: $blue_base;
                font-size: 15px;
                font-weight: normal;
                font-family: $open-sans;
            }

            &-right {
                font-size: 15px;
                font-weight: normal;
                font-family: $open-sans;
                text-align: right;

                a {
                    color: $blue_link;
                    margin-left: 7px;
                }
            }
        }

        &-top {
            background: $blue_base;

            h3 {
                @include fluid-font(30px);
                color: $blue_text;
                font-family: $work-sans;
                font-weight: normal;
                text-transform: uppercase;
            }

            &-line {
                height: 15px;
                background: $text_brown;
            }

            .container {
                padding-top: 5rem;
                padding-bottom: 2.2rem;
                position: relative;

                .row {
                    flex-wrap: wrap;

                    .col {
                        &:nth-child(1) {
                            flex: 0 0 26%;
                        }

                        &:nth-child(2) {
                            flex: 0 0 24%;
                        }

                        &:nth-child(3) {
                            flex: 0 0 24%;
                        }

                        &:nth-child(4) {
                            flex: 0 0 26%;
                        }

                        .logo-container {
                            margin-bottom: 1.1rem;
                        }

                        .footer-about {
                            font-weight: normal;
                            font-family: $open-sans;
                            //@include rlh(26px);
                            line-height: 2;
                            margin-bottom: 1.2rem;
                        }

                        .footer-contacts {
                            @include rlh(26px);
                            //a {
                            //    color: $violet-alt;
                            //    @include fluid-font(21px);
                            //}

                            span {
                                @include fluid-font(17px);
                            }

                            li {
                                position: relative;
                                padding-left: 30px;
                                margin-bottom: 1em;
                            }

                            i {
                                position: absolute;
                                display: inline-block;
                                line-height: inherit;
                                left: 0px;
                                color: $blue_link;

                                &.icon-mail {
                                    font-size: 14px;
                                }

                                &.icon-mobile, &.icon-map-point {
                                    font-size: 18px;
                                    left: 6px;
                                }
                            }
                        }

                        .footer-quick-links {
                            text-transform: uppercase;
                            font-weight: 600;
                            @include fluid-font(17px);
                            @include letter-spacing(100);
                            display: inline-block;
                            text-align: left;

                            li {
                                margin-bottom: 1em;
                            }

                            a {
                                &:hover, &.active {
                                    color: $brown_base;
                                }
                            }
                        }

                        .footer-icon-links {
                            a {
                                display: flex;
                                align-items: center;
                                margin-bottom: 2.5rem;
                                @include fluid-font(20px);
                                font-weight: normal;
                                font-family: $work-sans;
                                justify-content: flex-start;

                                i {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    margin-right: 5px;
                                    width: 3.5rem;
                                    height: 3.5rem;
                                    flex: 0 0 3.5rem;
                                    background-color: $violet-alt;
                                    font-size: 1.5rem;
                                    @include transition;
                                }

                                &:hover {
                                    i {
                                        background-color: $brown_base;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &-bottom {
            background: $brown_base;
            color: $white;
            font-family: $montserrat;
            padding-top: 1.5em;
            padding-bottom: 1.5em;

            p {
                margin-bottom: 0;
                @include letter-spacing(200);
            }
        }
    }

    @include bbetween(md, lg){
        .footer {
            &-top{
                .container .row .col {
                    &:nth-child(1) {
                        flex: 0 0 24%;
                    }

                    &:nth-child(2) {
                        flex: 0 0 23%;
                    }

                    &:nth-child(3) {
                        flex: 0 0 23%;
                    }

                    &:nth-child(4) {
                        flex: 0 0 30%;
                    }
                }
            }
        }
    }
    @include bbetween(md, nm) {
        .footer {
            &-top {
                .container .row .col {
                   .footer-contacts span {
                       font-size: 10px;
                   }
                }
            }
        }
    }
    @include bup(md) {
        .footer {
            &-top {
                .btn {
                    width: 240px;
                }

                .col {
                    &:nth-child(1) {
                        //padding-right: 50px;
                    }

                    &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4) {
                        border-right: 2px solid rgba($color: $footer-divider, $alpha: .3);
                        padding: 0 50px;
                    }

                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4) {
                        padding-top: 4rem;
                    }

                    &:nth-child(4) {
                        border-right: none;
                        padding-right: 0;
                        //padding-top: 6rem; //в случае опасности дернуть за кольцо
                    }
                }
            }
        }
    }

    @include bdown(md) {
        .footer-top .container {
            .row {
                display: flex;

                .col {
                    padding-left: 15px !important;
                    padding-right: 15px !important;

                    &:nth-child(1) {
                        flex-basis: 100% !important;
                        border: none;
                    }

                    &:nth-child(2) {
                        flex-basis: 50% !important;
                        border: none;
                    }

                    &:nth-child(3) {
                        flex-basis: 50% !important;
                        border: none;
                    }

                    &:nth-child(4) {
                        flex-basis: 100% !important;
                        border: none;
                    }

                    .social-links {
                        padding-left: 0;
                        justify-content: center;
                        padding-bottom: 21px;
                    }
                }
            }

            .logo-container, .footer-about {
                text-align: center;
                margin-bottom: 1rem;
            }
        }

        .footer {
            &-btn {
                &-wrap {
                    top: 5px;
                    justify-content: center;
                }

                &-label {
                    display: none;
                }
            }

            &-bottom {
                .footer-text-left, .footer-text-right {
                    text-align: center;
                }
            }
        }
    }

    @include bdown(xs) {
        .footer {
            &-btn-wrap {
                .btn span {
                    font-size: 15px;
                }

                .btn i, .btn .vertical-sep {
                    display: none;
                }
            }
        }
    }

    @include bdown(lg) {
        .footer {
            &-top {
                .col {
                    &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4) {
                        border-right: 2px solid rgba($color: $footer-divider, $alpha: .3);
                        padding: 0 30px;
                    }

                    &:nth-child(2) {
                        padding-right: 0;
                    }

                    &:nth-child(4) {
                        border-right: none;
                        // padding-right: 0;
                        // padding-top: 4rem;
                        // flex-basis: 100% !important;
                    }
                }
            }
        }
    }
}