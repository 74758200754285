@import url('https://fonts.googleapis.com/css2?family=Graduate&family=Merriweather:ital,wght@1,700&family=Montserrat&family=Open+Sans:wght@400;600&family=Poppins:wght@400;600&family=Roboto:wght@400;700&family=Rubik:wght@300;400;500;700&family=Work+Sans:wght@500;600;700&family=Zilla+Slab:wght@700&display=swap');
@import "icomoon";

$open-sans: 'Open Sans', sans-serif;
$graduate: 'Graduate', cursive;
$merriweather: 'Merriweather', serif;
$montserrat: 'Montserrat', sans-serif;
$poppins: 'Poppins', sans-serif;
$roboto: 'Roboto', sans-serif;
$rubik: 'Rubik', sans-serif;
$work-sans: 'Work Sans', sans-serif;
$zilla: 'Zilla Slab', serif;
$icomoon: "icomoon";

$rubik-regular: 400;
$rubik-medium: 500;
$roboto-regular: 400;
$roboto-medium: 600;
$bold: 700;


//Old
$lora: 'Lora', sans-serif;
$oswald: 'Oswald', sans-serif;
$ebg: 'EB Garamond', serif;
$esteban: 'Esteban', serif;
$vibes: 'Great Vibes', cursive;
$raleway: 'Raleway', sans-serif;