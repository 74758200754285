header {
    transition: background-color .4s;

    .container {
        position: relative;
        width: 100%;
    }

    .row {
        align-items: center;

        &.full-h {
            height: 100%;
        }
    }

    .col {
        &.big-space {
            flex: 1 1 50%;
        }

        &.small-space {
            flex: 1 1 25%;
        }

        &.flex {
            display: flex;
        }

        &.flex-center {
            align-items: center;
        }
    }

    .header {
        &-search-container {
            display: flex;
            justify-content: flex-end;
            align-items: baseline;
            padding-top: 15px;
        }

        &-btn-wrap {
            position: relative;
            z-index: 111;
            top: 11px;
            display: flex;

            .btn {
                border-top-right-radius: 0;
                border-top-left-radius: 0;
                width: 240px;
            }

            .btn:nth-child(1) {
                margin-right: 25px;
            }
        }

        &-icons {
            display: flex;
            margin-right: 130px;
            align-items: center;

            i {
                margin-right: 22px;
                color: #fff;
                font-size: 14px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        &-top {
            padding-top: 5px;
            padding-bottom: 8px;
            position: relative;
            display: flex;
            height: 40px;
            background: $blue_base;

            &-container {
                display: flex;
                justify-content: flex-end;
            }

            &-info {
                @include fluid-font(17px);
                display: flex;
                align-items: center;
                margin-right: 1rem;
                border-right: 1px solid rgba($color: #2C3139, $alpha: .55);
                color: #fff;
                font-family: $rubik;
                font-weight: $rubik-medium;
                position: relative;
                top: 7px;
                font-size: 18px;
                width: 80px;
            }

            form {
                @include bup(xs){
                    width: 350px;
                }
                @include bdown(xs){
                    width: 100%;
                }

                color: $blue;

                .search {
                    border-bottom: 2px solid $blue;
                    display: flex;
                    align-items: center;
                    ::placeholder {
                        color: $blue;
                    }
                    input {
                        max-width: 90%;
                        border: none;
                        background-color: transparent;
                        font-weight: 500;
                        color: $blue;
                        @include fluid-font(23px);
                        padding: .5em 1em 0.2em 1em;
                        flex: 1 1 0;
                    }

                    button {
                        font-size: 25px;
                        background-color: transparent;
                        border: none;
                        flex: 0 0 auto;
                        padding: 0;
                        color: $blue;
                        &:hover {
                            color: $brown_base;
                        }
                    }
                }
            }

            @include bup(md) {
                //border-top: 10px solid $blue;

                .top-links {
                    padding-right: 7%;
                }
                .top-phone-block {
                    margin-left: 5em;
                }

                .col {
                    &:nth-child(2){
                        //flex: 1 1 50%;
                    }
                }
            }

            @include bbetween(md, lg) {
                .header-btn-wrap {
                    //top: -20px;
                }
            }

            @include bbetween(md, nm){
                .top-links {
                    flex-direction: column;
                    .separator {
                        display: none;
                    }
                    .icon-mobile {
                        margin-bottom: .5rem;
                    }
                }
            }

            @include bdown(md) {
                padding-top: 0;
                padding-bottom: 0;

                .logo {
                    text-align: center;
                }

                .container {
                    justify-content: center;
                }

                .header-icons, .header-top-info {
                    display: none;
                }

                .header-top-container {
                    justify-content: center;
                }

                .header-btn-wrap {
                    top: -10px;

                    .btn {
                        width: auto;
                    }
                }

                .col {
                    margin-bottom: 10px;
                }
                .col:nth-child(2) {
                    justify-content: space-between;
                }
                .col:nth-child(3) {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 0;
                }
                .soc-icons {
                    display: none;
                }
                .top-links {
                    .separator, span {
                        display: none;
                    }
                }
                .top-phone-block {
                    display: block;
                    padding-left: 0;
                    span {
                        display: none;
                    }
                    a {
                        display: inline-block;
                    }
                }
            }

            @include bdown(xs){
                .col:nth-child(2) {
                    display: block;
                }

                .header {
                    &-info {
                        border-right: none;
                        padding-right: 0;
                        margin-right: 0;
                        text-align: center;
                        margin-bottom: .5rem;
                        color: #fff;
                        font-family: $rubik;
                        font-weight: $rubik-medium;
                    }

                    &-btn-wrap {
                        .btn span {
                            font-size: 15px;
                        }

                        .btn i, .btn .vertical-sep {
                            display: none;
                        }
                    }
                }
            }

            @include bdown(xxs){
                .header-contacts {
                    display: block;
                    text-align: center;
                }
            }
        }

        &-center {
            height: 100px;
            background: #fff;
            position: relative;

            &-logo-wrap {
                display: flex;
                align-items: center;
            }

            .cellphone {
                display: inline-block;
                font-weight: $rubik-regular;
                font-family: $rubik;
                color: $blue_text;
                padding-left: 25px;
                position: relative;
                font-size: 18px;
                white-space: nowrap;

                i {
                    position: absolute;
                    left: 0;
                    top: 2px;
                    font-size: 20px;
                }

                &.tablet {
                    display: none;
                }
            }

            .vertical-sep {
                height: 80px;
                margin-right: 20px;
                margin-left: 30px;
            }

            .input-wrap {
                margin-right: 70px;

                input {
                    &::placeholder {
                        color: rgba($brown_base, .9);
                    }
                }

                &:after {
                    position: absolute;
                    content: "";
                    right: -34px;
                    border-right: 1px solid rgba($brown_base, .5);
                    height: 23px;
                }
            }

            .title {
                color: $brown_base;
                font-family: $roboto;
                font-size: 20px;
                font-weight: $roboto-medium;
                display: flex;
                flex-direction: column;

                .cellphone {
                    margin-top: 5px;
                }
            }

            .logo {
                width: 296px;
                height: 105px;

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }

            @include bdown(nm) {
                height: 110px;
                padding-top: 20px;

                .nav-container {
                    display: none;
                }

                .input-wrap {
                    flex: 1;
                    margin-right: 30px;

                    &:after {
                        display: none;
                    }
                }

                .logo {
                    height: 90px;
                }
            }

            @include bdown(md) {
                height: 120px;

                &-logo-wrap {
                    flex-direction: column-reverse;
                    align-items: flex-start;

                    .vertical-sep {
                        display: none;
                    }

                    .logo {
                        height: 63px;
                        width: 145px;
                    }
                }

                .title {
                    font-size: 15px;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    flex-direction: row;

                    .cellphone {
                        font-size: 15px;
                    }
                }

                .cellphone.desktop, .header-search-container {
                    display: none;
                }
            }
        }

        &-bottom {
            background: $brown_base;
            height: 55px;

            .menu-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 0;

                .input-wrap {
                    width: auto;
                    flex: 1;
                    margin-right: 50px;

                    input {
                        &.input {
                            background: transparent;
                            border: none;
                            border-bottom: 1px solid rgba($color: $white, $alpha: .5);
                            color: $white;
                            padding: 10px 30px 2px 0;
                        }

                        @include bdown(md) {
                            &::placeholder {
                                color: rgba($white, .5);
                            }
                        }
                    }

                    i {
                        color: rgba($white, .5);
                    }
                }
            }

            nav {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                a {
                    position: relative;
                    text-transform: uppercase;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex: 0 0 auto;
                    @include transition;
                    line-height: 26px;
                    @include font-count(10, 23);
                    font-weight: 400;
                    font-family: $rubik;
                    background-color: transparent;
                    padding: .4rem .7rem;

                    &.nav-item-active {
                        color: $blue_base;
                    }
                    &:hover {
                        color: $blue_base;
                    }
                }
            }

            @include bup(md){
                nav {
                    padding: 10px 0;

                    a {
                        &.nav-item-active {
                            position: relative;

                            i {
                                font-size: 14px;
                                margin-left: 10px;
                                color: #fff;
                            }
                        }
                    }
                }
            }

            @include bup(lg){
                @include font-count(10, 22);
            }

            @include bdown(lg) {
                nav a {
                    @include fluid-font(20px);
                    padding: 0.3rem 0.5rem
                }
            }

            @include bdown(md) {
                height: auto;
                padding: 0;
                font-size: 16px;
                display: flex;
                align-items: center;

                .menu-container {
                    min-height: 55px;
                }

                .container {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    position: static;
                    padding-left: 0;
                    padding-right: 0;
                }

                .nav-container {
                    display: none;
                }

                nav {
                    flex-direction: column;
                    padding-left: 0;
                    padding-right: 0;
                    width: 100%;
                    justify-content: space-evenly;
                    background: $blue_base;

                    a {
                        display: block;
                        font-size: 16px;
                        padding: .5em 5%;

                        i {
                            margin-left: 10px;
                            color: $white;
                        }

                        &.nav-item-active, &:hover {
                            color: $brown_base;

                            &:after {
                                content: none;
                            }
                        }
                        &:last-of-type {
                            margin-left: 0;
                        }
                        &:before {
                            content: none;
                        }
                        &:after {
                            content: none;
                        }
                    }
                }
            }

            span.nav-toggle {
                text-align: center;
                font-family: 'icomoon';
                font-size: 2em;
                min-width: 35px;
                display: block;
                transition: transform .3s ease;
                transform-origin: 50% 50%;
                color: $white;
                &:before {
                    content: "\e90d";
                }
                &.close {
                    transform: rotate(-180deg);
                }
                &.close:before {
                    content: "\e90e";
                }
            }
        }

        @include bbetween(md, lg) {
            .logo {
                height: 116px;
                width: 260px;
            }
        }

        @include bbetween(hg, hd) {
            .empty {
                display: none;
            }

            .need-wrap {
                flex-wrap: wrap;
            }
        }
    }
}

#logout-form {
    display: none;
}

.main-slider-loading {
    header {
        background-color: rgba(0, 0, 0, .8);
    }
}