.single-article-navigation {
    display: flex;
    justify-content: space-between;
    padding: .5rem 0;
    a {
        color: $blue_link;
        font-size: 14px;
        font-weight: 500;
        @include letter-spacing(120);
        &:hover {
            //color: $violet;
        }
    }
}
.pagination-container {
    text-align: center;
    .pagination-title {
        font-size: 14px;
        font-family: $open-sans;
    }
    .text-center {
        @include font-count(12, 14);
        color: $text_main;
    }
}
.pagination {
    display: inline-flex;
    align-items: center;
    padding-bottom: 20px;
    @include bdown(md) {
        display: flex;
        justify-content: space-between; 
    }
    .pagination-list {
        flex-grow: 1;
        display: flex;
        justify-content: space-around;
        li {
            display: inline-block;
            margin-right: 7px;
            a {
                color: $text_minor_second;
                text-align: center;
                padding: 8px 16px;
                font-family: $montserrat;
                font-weight: 700;
                // border: 1px solid #C8C8C8;
                // border-radius: 3px;
                @include font-count(12,28);
                &:hover, &.active-pag {
                    // background-color: $green;
                    color: $blue;
                }
                &.active-pag {
                    //text-decoration: underline;
                }
            }
        }
        @include bdown(md) {
            li {
                margin: 0 1px;
                a {
                    min-width: 0;
                    padding: 9px 15px;
                }
            }
        }
        @include bdown(xs) {
            li a {
                padding: 9px;
            }
        }
    }
    .pag-arrow {
        //border-radius: 5px;
        // font-size: 2em;
        // border: 2px solid transparent;
        flex-shrink: 0;
        display: flex;
        align-content: center;
        justify-content: center;
        border-radius: 2rem;
        img {
            width: .8em;
            height: .8em;
        }
        &.hidden--mobile {
            //color: $violet;
            font-size: 14px;
            @include letter-spacing(160);
        }
        // &:hover {
        //     background-color: $violet;
        //     color: #fff;
        //     img {
        //         filter: brightness(50%);
        //     }
        // }
        &:first-of-type {
            margin-right: 20px;
            i {
                margin-right: 1rem;
            }
        }
        &:last-of-type {
            margin-left: 20px;
            i {
                margin-left: 1rem;
            }
        }
        @include bdown(md) {
            min-width: 0;
            &:first-of-type,
            &:last-of-type {
                i {
                    margin: 0;
                }
            }
            span {
                display: none;
            }
            &:first-of-type {
                margin-right: 7px;
            }
            &:last-of-type {
                margin-left: 7px;
            }
        }
        @include bdown(xs) {
            min-width: 30px;
            padding: 5px;
            &:first-of-type {
                margin-right: 5px;
            }
            &:last-of-type {
                margin-left: 5px;
            }
        }
    }
    &.single-pagination {
        display: flex;
        .pagination-list {
            justify-content: space-between;
        }
    }
}
.featured-media-container {
    img {
        width: 100%;
    }
    &.video, .video {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        iframe {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
        }
    }
}