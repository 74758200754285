$images: '../../images';
//$icons: '#{$images}/icons';

$blue_base: #002742;
$blue_second_base: #0C73B6;
$blue_second_dark: #084974;
$blue_overlay: #122A43;
$blue_link: #9FCEFD;
$blue_text: #2B8FD0;
$dark_blue_text: #032C56;
$blue_bg: #F1F8FC;
$brown_base: #a59873;
$brown_base_dark: #655d46;
$text_brown: #887F67;
$text_main: #2C2C2C;
$text_minor: #40454A;
$text_minor_second: #5F6A75;
$back_link: #A2A0A0;
$white: #FFFFFF;
$footer-divider: #15BEF0;

// icons
$facebook: #3B5998;
$twitter: #7ED0E2;
$linkedin: #486AAE;
$pinterest: #e60023;
$gray_icon: #707070;

// dots-bg
$dot-size: 3px;
$dot-space: 9px;

//old
$blue: #008FAC;
$violet: #432F64;
$violet-alt: #7878B7;
$light-violet: #695C95;
$light-blue: #86D4EF;

//breakpoints
$breakpoints: (
    'hd': 1919px,
    'hg': 1600px,
    'lg': 1400px,
    'nm': 1240px,
    'md': 991px,
    'sm': 768px,
    'xs': 480px,
    'xxs': 385px
)!default;