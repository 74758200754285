.block-shadow {
    @extend %block-shadow-2;
    &--top{
        @extend %block-shadow-3;
    }
}
.bordered-img-container {
    display: inline-block;
    img {
        position: relative;
        border: .625rem solid #fff;
        box-shadow: 0 0 4px #cbcbcb;
        z-index: 5;
    }
}
.sign-up {
    color: $white;
    background-repeat: no-repeat;
    background-position: 100% 107%;
    background-size: auto;
    padding: 1rem 0;
    .row {
        align-items: center;
        h3 {
            text-align: left;
            color: inherit;
            @include fluid-font(41px);
            font-family: $lora;
            font-weight: 700;
            line-height: 1;
            & + p {
                @include fluid-font(14px);
                font-weight: 500;
                color: $white;
                opacity: .5;
                @include letter-spacing(200);
            }
        }
    }
    form {
        display: flex;
        align-content: center;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    }
    .input-container {
        position: relative;
        border-radius: 5px;
        margin-right: .5em;
        background-color: #fff;
        i {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 1em;
            color: $violet-alt;
            font-size: 1.3em;
        }
    }
    input {
        background: transparent;
        border: none;
        color: #A1A1A1;
        flex: 1 1 0;
        padding: 0 4em 0 1em;
        height: 100%;
        font-family: $poppins;
        width: 100%;
        @include font-count(13, 18);
    }
    button.btn {
        border: none;
        @include fluid-font(13px);
        @include transition;
        white-space: nowrap;
        font-family: $poppins;
        background-color: $light-violet;
        color: #fff;
        font-weight: 500;
        @include letter-spacing(200);
        &:hover {
            background-color: $violet;
        }
    }
    p {
        margin-bottom: 0;
    }
    @include bup(lg){
        .input-container {
            min-width: 70%;
        }
    }
    @include bup(md) {
        .col:nth-child(1){
            padding-left: 7%;
        }
        .input-container {
            min-width: 60%;
            margin-right: 2em;
        }
        background-size: auto 90%;
        background-position: 83% 50%;
    }
    @include bdown(md) {
        .row h3, .row h3 + p {
            text-align: center;
        }
    }
}
.book-top-bg {
    background-repeat: no-repeat;
    background-position: left -5%;
}
.calendar-top-bg {
    background-repeat: no-repeat;
    background-position: right 3%;
}
.top-link {
    &--list {
        display: flex;
        justify-content: space-between;
        li {
            flex: 0 0 23%;
            @extend %double-shadow;
            height: 190px;
            a {
                height: 100%;
                border: 1px solid #D1CFCF;
                padding: 9px;
                position: relative;
                width: 100%;
                z-index: 10;
                background-color: $white;
                .inner {
                    top: 9px;
                    height: calc(100% - 18px);
                    left: 9px;
                    width: calc(100% - 18px);
                    position: absolute;
                    overflow: hidden;
                    &:before {
                        content: "";
                        display: block;
                        position: absolute;
                        z-index: 7;
                        height: 100%;
                        top: 0;
                        right: -50%;
                        width: 50%;
                        transition: right .4s ease-in-out;
                    }
                    &:after {
                        content: "";
                        display: block;
                        position: absolute;
                        height: 100%;
                        top: 0;
                        right: 0%;
                        width: 50%;
                    }
                }
                img {
                    position: absolute;
                    left: 0;
                    width: 80%;
                    top: 0;
                    height: 100%;
                    object-fit: cover;
                }
                &:hover {
                    .inner {
                        &:before {
                            right: 0;
                        }
                    }
                    .top-link--title {
                        color: $light-blue;
                    }
                }
            }
        }
    }
    &--title {
        color: $white;
        position: absolute;
        right: 18px;
        top: 50%;
        transform: translateY(-50%);
        color: $white;
        z-index: 20;
        transition: color .4s ease-in-out;
        @include fluid-font(24px);
        font-family: $montserrat;
        font-weight: 600;
        word-spacing: 9999999px;
        text-align: right;
        // word-break: break-all;
    }
    @include bup(nm){
        &s {
            margin-top: -1rem;
            padding-top: 0;
        }
    }
    @include bdown(nm){
        &s {
            margin-top: 0;
        }
        &--list {
            flex-wrap: wrap;
            li {
                flex: 0 0 48%;
                margin-bottom: 2%;
            }
        }
    }
    @include bdown(sm) {
        &--list {
            li {
                flex: 1 1 100%;
            }
        }
    }
}
.read-more {
    display: inline-flex;
    align-items: center;
    span {
        color: $brown_base;
        margin-right: 1rem;
        @include transition;
        @include letter-spacing(200);
        @include fluid-font(14px);
    }
    i {
        color: $light-blue;
        @include transition;
    }
    &:hover {
        span {
            color: $blue;
        }
        i {
            color: $blue;
        }
    }
}
.promo {
    .col:nth-child(2) {
        display: flex;
        align-items: center;
    }
    img {
        padding: 0 1rem;
    }
    p {
        margin-bottom: 0;
    }
    .row {
        align-items: center;
    }
    h3 {
        font-family: $montserrat;
        font-weight: 700;
    }
    @include bup(md){
        p {
            max-width: 80%;
        }
        .col:nth-child(2) {
            border-left: 1px solid $white;
        }
    }
    @include bdown(md){
        .row {
            flex-direction: column-reverse;
        }
        .col:nth-child(1) {
            margin-bottom: 0;
        }
        .col:nth-child(2) {
            margin-bottom: 1rem;
        }
    }
}
section.our-news {
    background-position: 25% 0;
    background-repeat: no-repeat;
    .decorated {
        @include bup(md){
            max-width: 50%;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .our-news-card {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        height: 100%;
        border-top: 1rem solid $violet-alt;
        .btn-container {
            text-align: right;
            position: absolute;
            left: 0;
            width: 100%;
            bottom: 0.5rem;
            align-items: center;
            font-size: 14px;
            span {
                color: $light-blue;
                margin-right: .5rem;
                @include transition;
            }
            i {
                color: #9B9B9B;
            }
        }
        &-inner {
            padding: 1.1rem 0 3rem 0;
            flex-grow: 1;
            position: relative;
        }
        &-title {
            @include fluid-font(29px);
            font-family: $ebg;
            font-weight: 500;
            color: $violet-alt;
            margin-bottom: .5rem;
        }
        &-excerpt {
            @include fluid-font(15px);
            color: #777;
            margin-bottom: .7rem;
            @include rlh(30px);
        }
        &-date {
            color: $blue;
            @include transition;
            @include fluid-font(16px);
            border-top: 1px solid $blue;
            border-bottom: 1px solid $blue;
            padding: .5rem 0;
            margin-bottom: .7rem;
            &:before {
                content: "\e91d";
                display: inline-block;
                padding-right: 1em;
                font-family: $icomoon;
            }
        }
        &-media-wrapper {
            position: relative;
            height: 0;
            padding-bottom: (9 * 100% / 16);
            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &:hover {
            border-top-color: $violet;
            .btn-container {
                span {
                    color: $violet;
                }
            }
        }
    }
}
.get-involved {
    &--list {
        display: flex;
        li {
            a {
                @extend %block-shadow-1;
                position: relative;
                display: block;
                &:hover {
                    .get-involved--title{
                        background-color: #353939;
                    }
                }
            }
            img {
                display: block;
            }
            
        }
    }
    &--title {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 10%;
        text-align: center;
        background-color: $violet-alt;
        color: $white;
        @include font-count(12, 27);
        padding: 1rem 0;
        @include transition;
    }
    @include bup(md){
        &--list {
            li {
                border-left: 1px solid $white;
                border-right: 1px solid $white;
                &:first-of-type{
                    border-left: none;
                }
                &:last-of-type {
                    border-right: none;
                }
            }
        }
    }
    @include bdown(md){
        &--list {
            flex-wrap: wrap;
            li {
                flex: 0 0 50%;
                border: 1px solid $white;
            }
        }
    }
}

.program-blocks {
    background: $blue_bg;
    padding-bottom: 3rem;

    h2 + p {
        text-align: center;
        color: #fff;
        font-family: $poppins;
        font-weight: 600;
        @include font-count(14, 18);
        margin-bottom: 2em;
    }

    .row {
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .col {
        // flex: 0 1 30%;
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
    }

    .pb-item {
        outline: none;
        &--container {
            @extend %double-shadow;
            position: relative;
            margin-bottom: 1rem;
            width: 100%;

            &:before {
                content: "";
                display: block;
                background-color: rgba($blue_overlay, $alpha: .3);
                position: absolute;
                top: 0;
                height: 100%;
                left: 0;
                right: 0;
                @include transition;
                z-index: 15;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }
        }

        overflow: hidden;
        position: relative;
        display: block;
        //border: .625rem solid #fff;
        box-sizing: content-box;
        z-index: 5;
        box-shadow: 0 0 1px #CBCBCB;

        &--info {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 0 30px;
            text-align: center;
        }

        & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }

        .sliding-content {
            position: absolute;
            top: 100%;
            height: 100%;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
            z-index: 10;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            @include transition;

            i {
                font-size: 28px;
                background: $white;
                color: $brown_base;
                border-radius: 100%;
                width: 60px;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .pb-title, .pb-name {
        color: $blue_second_base;
        font-family: $work-sans;
        font-weight: 600;
        margin-bottom: 1rem;
        display: flex;
        //min-height: 54px;
        overflow: hidden;
        text-align: center;
        justify-content: center;
    }

    .pb-title {
        @include fluid-font(34px);
    }

    .pb-name {
        @include fluid-font(24px);
    }

    .pb-text {
        font-family: $rubik;
        font-weight: $rubik-regular;
        color: $text_minor;
        margin-bottom: 1rem;
        @include fluid-font(19px);
        text-overflow: ellipsis;
        overflow: hidden;
        @include rlh(27px);
    }

    .pb-name {
        margin-bottom: 0.5rem;
        min-height: auto;
        color: $blue_base;
        @include fluid-font(22px);
        font-family: $work-sans;
        font-weight: 500;
        justify-content: left;
        text-align: left;
    }

    .pb-position {
        font-family: $rubik;
        font-weight: $rubik-regular;
        color: $text_minor_second;
        @include fluid-font(16px);
        margin-bottom: 2rem;
        overflow: hidden;
        max-height: 32px;
        text-align: left;
    }
}
.team-slider {
    .pb-item {
        box-shadow: none;
        &--container {
            &:before {
                display: none;
            }
        }
    }
    .pb-position {
        max-height: none;
        height: 40px;
        margin-bottom: .5rem;
    }
}

.block--title {
    &-wrap {
        p {
            text-align: center;
            max-width: 70%;
            margin: auto;
            color: $text_minor_second;
            font-family: $rubik;
            font-weight: $rubik-regular;
            @include fluid-font(18px);
            margin-bottom: 3rem;
        }
    }

    &-wrap2 {
        p {
            font-family: $rubik;
            font-weight: $rubik-regular;
            color: $text_minor;
            @include fluid-font(19px);
        }
    }

    &-wrap, &-wrap2 {
        .title-big {
            text-align: center;
            color: $blue_second_base;
            font-family: $work-sans;
            font-weight: 700;
        }
        h1, h2, h3, h4, h5, h6, p, ul, ol {
            margin-bottom: 1rem;
        }
        ul {
            padding-left: 1rem;
            font-family: $rubik;
            font-weight: $rubik-regular;
            li {
                list-style-type: disc;
                @include fluid-font(19px);
            }
        }
    }
}

.about-blue-banner {
    background-color: $blue;
    color: $white;
    padding: 2rem;
    margin-bottom: 2rem;
    margin-left: 1rem;
    @include fluid-font(28px);

    &:after {
        z-index: -1;
    }

    h4 {
        color: $white;
        font-family: $montserrat;
        font-weight: 600;
        @include letter-spacing(20);
        border-bottom: 1px solid $light-blue;

    }

    p {
        color: $light-blue;
    }
}
.about-aside {
    margin-left: 1.5rem;

    h2 {
        color: $blue;
    }
}
.staff-list {
    display: flex;
    flex-wrap: wrap;

    li {
        flex: 0 0 25%;
        margin-bottom: 2rem;

        a {
            display: block;
            max-width: 85%;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .inner {
        position: relative;
        overflow: hidden;
        z-index: 5;
        @include framed($light-blue, $violet);

        img {
            position: relative;
            display: block;
        }
    }

    @include bdown(md) {
        li {
            flex: 0 0 50%;
        }
    }
    @include bdown(xs) {
        li {
            flex: 0 0 100%;
        }
    }
}
.board {
    .container {
        @include bup(md) {
            max-width: 1674px;
            margin-left: auto;
            margin-right: auto;
            padding-left: 7%;
            padding-right: 7%;
        }
    }

    &.blue-bg {
        h2 span {
            color: $white;
        }

        .staff-list {
            color: $white;

            .inner {
                @include framed($violet, $light-blue);
            }

            h5 {
                color: inherit;
            }

            p {
                color: $light-blue;
            }
        }
    }
}
.annual-report {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    li {
        flex: 0 0 23%;
        margin-top: 2%;

        a {
            @include fluid-font(19px);
            background-color: $white;
            padding: .5rem 1rem;
            border: 2px solid $light-blue;
            color: $blue;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &:hover {
                background-color: $text_minor_second;
            }
        }
    }

    @include bdown(md) {
        li {
            flex: 0 0 45%;
        }
    }
    @include bdown(xs) {
        li {
            flex: 0 0 100%;
        }
    }
}
.blurb {
&--list {
    display: flex;
    flex-wrap: wrap;
    margin-top: -2rem;
    li {
        flex: 0 0 33%;
    }
    &--item {
        display: block;
        font-weight: 500;
        font-family: $montserrat;
        @include fluid-font(28px);
        color: $blue;
        @extend %block-shadow-2;
        &:before {
            content: "";
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 4.6rem;
            height: 4.6rem;
            background-color: $light-blue;
            border-radius: 50%;
            position: relative;
            top: 2rem;
            @include transition;
        }
        span {
            display: block;
            text-align: center;
            padding: 2rem 0;
        }
        &:hover {
            color: $blue_base;

            &:before {
                background-color: $blue_base;
            }
        }
    }
    @include bdown(nm){
        li {
            flex: 0 0 50%;
        }
        &--item {
            font-size: 1rem;
            &:before {
                top: 1.5rem;
            }
        }
    }
    @include bdown(xs){
        li {
            flex: 0 0 100%;
        }
        &--item {
            font-size: 1rem;
            &:before {
                top: 1.5rem;
            }
        }
    }
    &.alt {
        justify-content: space-between;
        li {
            flex: 0 0 45%;
        }
        .blurb--list--item {
            height: 100%;
            &:before {
                background-color: $violet;
            }
            span {
                height: calc(100% - 4.6rem);
                display: flex;
                align-items: center;
                justify-content: center;
                &:before {
                    content: "";
                    position: absolute;
                    background-image: url(../../../images/icons/blurb-icon.svg);
                    background-repeat: no-repeat;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 2.222rem;
                    height: 2.222rem;
                    top: 3.5rem;
                }
            }
            &:hover {
                span {
                    background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%,rgba(134, 212, 239, 1) 50%, rgba(255, 255, 255, 0) 100%);
                }
            }
        }
        @include bdown(nm){
            .blurb--list--item {
                span {
                    &:before {
                        top: 3rem;
                        background-size: contain;
                    }
                }
            }
        }
    }

}
}
.number-info {
&--list {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    li {
        flex: 0 0 26%;
        display: flex;
        .sup {
            color: $blue;
            @include fluid-font(36px);
            font-family: $esteban;
        }
        .num {
            color: $blue;
            @include fluid-font(90px);
            line-height: 1;
        }
    }
}
&--text {
    color: $violet;
    @include fluid-font(20px);
    padding-top: 5px;
}
@include bdown(sm){
    &--list {
        flex-wrap: wrap;
        li {
            flex: 0 0 100%;
            display: flex;
            align-items: center;
        }
    }
}
}
.details-info {
&--list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 0;
    border-top: 2px solid #C0EAF8;
    border-bottom: 2px solid #C0EAF8;
    margin-bottom: 2rem;
    li {
        flex: 0 0 50%;
        display: flex;
        align-items: center;
        color: $blue;
        span {
            font-family: $montserrat;
            @include fluid-font(30px);
            @include letter-spacing(20);
            line-height: 1;
        }
    }
    @include bup(md){
        li {
            &:first-child {
                border-right: 2px solid #C0EAF8;
                padding-right: 2rem;

            }
            &:last-child {
                padding-left: 2rem;
            }
        }
    }
    @include bdown(sm){
        flex-wrap: wrap;
        li {
            flex: 0 0 100%;
            display: flex;
            align-items: center;
        }
    }
}
}
.symptoms-info {
&--list {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    li {
        flex: 0 0 25%;
        text-align: center;
        span {
            display: block;
            font-family: $montserrat;
            @include fluid-font(19px);
            font-weight: 300;
            color: $violet;
        }
        img {
            height: 6rem;
        }
    }
    @include bdown(sm){
        flex-wrap: wrap;
        li {
            flex: 0 0 50%;
            margin-bottom: 1rem;
        }
    }
}
}
.tcc {
&--list {
    li {
        display: flex;
        @extend %double-shadow;
        margin-bottom: 2rem;
        color: $white;
        @include bdown(md){
            flex-direction: column-reverse;
            .tcc--img {
                img {
                    position: static;
                }
            }
            &:after {
                max-width: 100% !important;
                left: 0 !important;
            }
        }
        &:after {
            max-width: 50%;
            z-index: -1;
            left: 50%;
        }
        &:nth-child(odd){
            .tcc--info {
                background-color: $violet;
            }
            @include bup(md){
                flex-direction: row-reverse;
                .tcc--info {
                    padding-left: 10%;
                }
            }
        }
        &:nth-child(even){
            .tcc--info {
                background-color: $blue;
                @include bup(md){
                    padding-right: 10%;
                }
            }
            &:after {
                left: 0;
            }
        }
    }
}
&--img,
&--info {
    flex: 0 0 50%;
}
&--img {
    position: relative;
    img {
        display: block;
        position: absolute;
        left: 0;
        width: 100%;
        top: 0;
        height: 100%;
        object-fit: cover;
    }
}
&--info {
    padding: 4rem 5%;
    h2, h3, h4 {
        color: inherit;
    }
    h2 {
        font-family: $montserrat;
        @include fluid-font(40px);
        @include letter-spacing(20);
    }
    h4 {
        color: #fff;
        font-family: $esteban;
        @include fluid-font(26px);
        display: inline-block;
        border-bottom: 1px solid $light-blue;
        padding-bottom: .3em;
        margin-bottom: 1.5em;
    }
    p {
        @include fluid-font(16px);
    }
    .link-more {
        color: $white;
        &:after {
            color: $violet-alt;
        }
    }
}
&--info.col {
    flex: 0 0 50%;
    padding: 4rem 5%;
    color: #fff;
    background-color: $violet;
}
&-single {
    @include bdown(md){
        .tcc--img {
            img {
                position: static;
            }
        }
    }
}
}
.more-resources {
&--list{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
        flex: 0 0 49%;
        margin-bottom: 2%;
        a {
            border: 3px solid $white;
            width: 100%;
            padding-top: 1rem;
            padding-bottom: 1rem;
            color: $white;
            background-color: $violet;
            &:hover {
                background-color: $blue;
                color: $white;
            }
        }
    }
}
}
.resources-list {
display: flex;
flex-wrap: wrap;
justify-content: space-between;
li {
    flex: 0 0 49%;
    margin-bottom: 2%;
    @extend %block-shadow-1;
    a {
        text-align: left;
        padding: 2rem;
        h4 {
            color: $violet;
        }
        background-image: linear-gradient(to right, #fff, transparent);
        &:hover {
            background-image: linear-gradient(to right, rgba(134, 212, 239, 1), rgba(134, 212, 239, 0));
        }
        span.read-more {
            span {
                color: $blue;
                margin-right: .5rem;
            }
            i {
                color: $violet-alt;
            }
        }
    }
}
@include bup(md){
    li a {
        padding-right: 11%;
    }
}
}
.advocacy-items {
&--list {
    & > li {
        padding: 1.5rem 0;
        border-top: 2px solid #C0EAF8;
        border-bottom: 2px solid #C0EAF8;
        margin-bottom: 2rem;
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
    }
}
&--inner {
    display: flex;
    @include bdown(md){
        flex-direction: column;
    }
    .text {
        margin-bottom: 1rem;
    }
    // flex-wrap: wrap;
}
&--img {
    flex-grow: 0;
    flex-shrink: 0;
    & + .advocacy-items--info {
        flex: 0 1 auto;
    }
    @include bdown(md){
        text-align: center;
        margin-bottom: 1rem;
    }
}
}
.bottom-links--list {
margin-top: 1rem;
display: flex;
align-items: center;
justify-content: center;

li {
    a {
        min-width: 150px;
        margin-left: 5px;
        margin-right: 5px;
        justify-content: center;
        color: $violet;
        &:hover {
            color: $white;
        }
    }
}
@include bdown(md){
    flex-direction: column;
    li {
        margin-bottom: .5rem;
    }
}
}
.contacts {
    &--row {
        h1, h2, h3, h4, h5, h6 {
            font-family: $work-sans;
            font-weight: $bold;
            @include fluid-font(42px);
        }

        @include bup(md) {
            .basis-60.col {
                padding-right: 0;

                & + .col {
                    padding-left: 0;

                    .shadow-left {
                        padding-left: 4rem;
                    }
                }
            }
        }
    }

    &--list {
        border-left: 2px solid $light-blue;
        font-family: $montserrat;

        li {
            padding-left: 1rem;

            i {
                //color: $violet;
                @include fluid-font(21px);
                padding-right: 1rem;
                position: absolute;
                left: 0;
                top: 0;
            }

            .contacts--list--item {
                position: relative;
                padding-left: 1.5rem;
                margin-bottom: 1rem;
            }

            a {
                @include fluid-font(21px);
                //color: $violet;
                font-weight: 700;

                span {
                    font-size: inherit;
                }
            }

            span {
                @include fluid-font(17px);
            }
        }
    }

    &--blue-banner {
        background-color: $blue;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: .5rem 2rem 1rem;

        &:after {
            z-index: -1;
        }

        h4 {
            color: $white;
            font-weight: 700;
            margin-bottom: 0;
            display: inline-block;
        }

        h2 {
            color: $light-blue;
            border-bottom: 1px solid $light-blue;
            display: inline-block;
        }

        .read-more {
            color: $white;
            display: inline-flex;
            align-items: center;

            &:hover {
                color: $white;
            }

            span {
                color: inherit;
            }

            i {
                color: $violet-alt;
            }
        }
    }

    &--donate-block {
        text-align: center;

        h2 {
            font-family: $montserrat;
            @include fluid-font(40px);
            font-weight: 700;
            color: $blue;
        }
    }
}
.follow-container {
    display: flex;
    align-items: center;
    margin: 3rem 0;
    flex-wrap: wrap;
    justify-content: center;

    &--title {
        margin-right: 2rem;
        font-family: $rubik;
        font-weight: $rubik-regular;
        @include fluid-font(18px);


        @media screen and (max-width: 600px) {
            margin-bottom: 1rem;
        }
    }

    //&--list {
    //    display: inline-flex;
    //
    //    li {
    //        @extend %share-style2;
    //        color: #edfaff;
    //    }
    //}
}

.volunteer-activity {
@include bup(md){
    .col {
        &:first-child {
            padding-right: 5%!important;
        }
        &:last-child {
            padding-left: 5%!important;
        }
    }
}
.shadow-left {
    padding-left: 3rem;
}
&--title,
&--list {
    @include fluid-font(28px);
    font-family: $montserrat;
    font-weight: 600;
    @include letter-spacing(20);
}
&--title {
    color: $blue;
    margin-bottom: 2rem;
}
&--list {
    color: $violet;
    @extend %uldots;
    li {
        margin-bottom: 1rem;
    }
}
}
.donate-top {
&--button {
    color: $white;
    background-color: $violet-alt;
    display: inline-flex;
    padding: 1rem 1.5rem;
    word-spacing: 9999999px;
    max-width: 357px;
    align-items: center;
    border: 1px solid #707070;
    @extend %block-shadow-1;
    &:hover {
        background-color: $blue;
    }
    i {
        @include fluid-font(48px);
    }
    span {
        @include fluid-font(31px);
        font-weight: 500;
        @include letter-spacing(200);
        line-height: 1;
    }
}
h3 {
    @include fluid-font(40px);
    font-weight: 700;
    font-family: $montserrat;
    margin-bottom: 0;
}
}
.donate-list {
    &--item {
        h5 {
            font-weight: 600;
            font-family: $poppins;
        }

        &:nth-child(odd) {
            background-color: rgba($light-blue, $alpha: .14);

            h5 {
                color: $violet-alt;
            }
        }

        &:nth-child(even) {
            background-color: $violet;

            h5, p {
                color: $white;
            }
        }

        .btn {
            color: $violet;

            &:hover {
                color: $white;
            }
        }

        .inner {
            display: flex;
            align-items: flex-start;
            max-width: 1190px;
            padding: 2rem 15px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .framed {
        flex: 1 0 20%;
        margin-right: 2rem;

        &:after {
            z-index: -1;
        }
    }

    &--info {
        flex: 1 1 80%;
    }
}

.single-event {
&--container {
    .inner {
        max-width: 1280px;
        margin-left: auto;
        margin-right: auto;
        background-color: rgba($color: #86D4EF, $alpha: .3);
        padding: 1rem 8%;

        h4 {
            //color: $violet;
            font-weight: 700;
        }
        h5.pretitle {
            @include fluid-font(16px);
            //color: $violet;
            display: inline-block;
            border-bottom: 1px solid $blue;
            padding-right: 3rem;
        }

        h2 + p {
            font-weight: 700;
            @include font-count(14, 17);
        }
        h2 span {
            font-weight: 400;
        }
    }
}
&--info {
    position: absolute;
    margin-top: 1rem;
    text-align: center;
    &--top {
        padding: 3px 2rem .5rem;
        background-color: $blue;
        color: $white;
        margin-bottom: 1rem;
    }
    &--weekday {
        @include fluid-font(21px);
        font-weight: 500;
    }
    &--date {
        font-family: $ebg;
        @include fluid-font(70px);
        color: $violet;
        line-height: 1;
    }
    &--month {
        font-family: $montserrat;
        @include fluid-font(23px);
        font-weight: 600;
        color: $violet;
    }
    &--year {
        font-family: $montserrat;
        @include fluid-font(17px);
        font-weight: 600;
        margin-bottom: 1rem;
    }
    &--time {
        @include fluid-font(20px);
        font-weight: 500;
        border-top: solid rgba(#86D4EF, $alpha: .4);
    }
    &--bottom {
        .btn {
            width: 100%;
            justify-content: center;
            color: $violet;
            &:hover {
                color: $white;
            }
        }
    }
}
&--featured-img {
    margin-top: -4rem;
    margin-right: -10rem;
    left: 3rem;
    position: relative;
    float: right;
    max-width: 60%;
    .framed {
        @extend %double-shadow;
        display: inline-block;
        &:after {
            z-index: -1;
        }
    }
}
@include bdown(hg){
    &--container {
        .inner {
            padding-left: 11rem;
        }
    }
    &--featured-img {
        margin-top: 0;
        margin-right: 0;
    }
}
@include bdown(nm){
    &--featured-img {
        float: none;
        max-width: 100%;
        position: static;
    }
}
@include bdown(xs){
    &--container {
        .inner {
            padding-left: 8%;
        }
    }
    &--info {
        position: static;
        margin-bottom: 2rem;
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
}
}
.sponsors {
    background-color: $white;

    &--container {
        display: flex;
        padding: 2rem 2rem 0;

        & > * {
            flex: 0 0 50%;
        }
    }

    &--list {
        display: flex;
        margin-bottom: 2rem;
        flex-wrap: wrap;

        &--large {
            flex-direction: column;
        }
    }

    &--title {
        color: $blue;
        font-family: $montserrat;
        font-weight: 600;
        @include fluid-font(28px);
        @include letter-spacing(20);
        margin-bottom: .5rem;
    }

    &--pretitle {
        font-family: $montserrat;
        max-width: 50%;
        color: $violet;
        border-bottom: 2px $light-blue solid;
        padding-bottom: .5rem;
        margin-bottom: 1rem;
    }

    @include bdown(md) {
        &--container {
            display: block;
        }
    }
    @include bdown(xs) {
        &--list {
            flex-direction: column;
            align-items: center;
        }
    }
}

.map-container {
iframe {
    width: 100%;
    height: 517px;
}
}
.featured-media-container.video {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;

    iframe {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
    }
}
.media-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;

    iframe {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
    }
}
.img-cover {
    object-fit: cover;
    font-family: 'object-fit: cover;';
}

.soc-share-container {
    padding-top: .5em;
    padding-right: 2%;
    display: flex;
    align-items: center;
    @extend %share-style4;
    display: inline-flex;
    @include bdown(md) {
        max-width: 190px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 25px;
        float: none;
        padding-right: 0;
    }
    .ssc-title {
        @include fluid-font(16px);
        color: $text_minor_second;
        font-family: $merriweather;
        font-weight: 700;
        font-style: italic;
        margin-right: 10px;
        text-transform: none;
    }
    ul {
        display: flex;
        justify-content: space-between;

        li {
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }

            a {
                width: 33px;
                height: 33px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $white;
                background: $gray_icon;
                @include fluid-font(20px);

                &.icon-facebook {
                    background: $facebook;
                }

                &.icon-twitter {
                    background: $twitter;
                    @include fluid-font(16px);
                }

                &.icon-linkedin {
                    background: $linkedin;
                }

                &.icon-pinterest {
                    background: $pinterest;
                }

                &.icon-mail {
                    @include fluid-font(16px);
                }

                &:hover {
                    opacity: .8;
                }
            }
        }
    }
}

.aside {
    &.col {
        flex: 0 0 20%;
        margin-left: 3%;
    }
    &--title {
        margin-bottom: 1em;
    }
    &--nav {
        @include fluid-font(17px);
        li {
            margin-bottom: 1em;
        }
        a {
            color: $text_minor_second;
            &:hover, &.active {
                color: $blue_base
            }
        }
    }

    &--container {
        margin-bottom: 1.5rem;
        // border-bottom: 1px solid #C8C8C8;
    }
}

.news {
    h1, h2, h3, h4, h5, h6 {
        text-transform: uppercase;
    }
    .aside {
        &--title {
            font-family: $work-sans;
            @include fluid-font(24px);
            font-weight: 700;
            color: $blue_base;
            margin-bottom: 3rem;
            text-transform: none;
        }
        &--nav {
            font-family: $rubik;
            font-weight: $rubik-regular;
            @include fluid-font(22px);
            a {
                color: $text_minor;

                &.active {
                    color: $blue_second_base;
                }
            }
        }
    }
    @include bup(md){
        .aside {
            padding-left: 15px;
        }
    }

    &-block {
        background: $white;
        padding: 40px 0;

        .news--card-container {
            max-width: 400px;
            box-shadow: 0 0 10px 0 #f3f1f1;
            border-radius: 10px;
            position: relative;
            overflow: hidden;
            height: 100%;

            .featured-media-container {

            }

            .pb-item {
                position: relative;
                display: block;
                box-sizing: content-box;
                z-index: 5;
                height: 240px;

                &--info {
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding: 1.5rem;
                }

                & > img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    display: block;
                }

                &.video, .video {
                    position: relative;
                    padding-bottom: 56.25%;
                    height: 0;

                    iframe {
                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 100%;
                        width: 100%;
                    }
                }
            }
        }

        .title-big {
            text-align: center;
            color: $blue_second_base;
        }

        .btn-wrap {
            display: flex;
            justify-content: center;
            margin-top: 2rem;
        }

        @include bdown(md) {
            .col {
                display: flex;
                justify-content: center;
            }
        }

        .pb-title {
            color: $blue_second_base;
            @include fluid-font(22px);
            font-family: $work-sans;
            font-weight: 500;
            margin-bottom: 4rem;
            min-height: 54px;
        }

        .date {
            font-family: $rubik;
            font-weight: $rubik-regular;
            color: $text_minor_second;
            @include fluid-font(16px);
        }
    }
}

.present-block {
    background: $white;
    padding: 15px 0;
    min-height: 800px;

    &-content {
        padding-top: 80px;
        padding-bottom: 40px;

        .title-small {
            color: $brown_base;
            font-family: $rubik;
            font-weight: $rubik-regular;
            @include fluid-font(26px);
            margin-bottom: 24px;
        }

        .title-big {
            color: $blue_second_base;
            font-family: $work-sans;
            font-weight: 600;
            @include fluid-font(42px);
            line-height: 1.3;
            margin-bottom: 24px;
        }

        .text {
            color: $text_minor;
            font-family: $rubik;
            font-weight: $rubik-regular;
            @include fluid-font(22px);
            margin-bottom: 30px;
        }
    }

    &-right {
        .image-wrap {
            position: relative;

            img {
                max-width: 500px;
            }

            .dotted {
                width: 350px;
                height: 350px;
                background:
                        linear-gradient(90deg, #fff ($dot-space - $dot-size), transparent 1%) center,
                        linear-gradient(#fff ($dot-space - $dot-size), transparent 1%) center,
                        #d9d9d9;
                background-size: $dot-space $dot-space;
            }

            &.rect {
                .dotted {
                    height: 250px;
                }

                .image-wrap--item-bottom {
                    margin-top: 140px;
                    margin-right: 215px;
                }
            }

            &.small {
                .dotted {
                    height: 300px;
                    width: 300px;
                }

                .image-wrap--item-bottom {
                    margin-top: 120px;
                    margin-right: 200px;
                }
            }

            // clearfix
            &::after {
                content: ' ';
                display: table;
                clear: both;
            }

            &--item {
                &-top {
                    float: right;
                    margin-left: -100%;
                    padding-bottom: 60px;
                    padding-left: 60px;
                    position: relative;
                    z-index: 1;

                    &:before {
                        content: "";
                        display: block;
                        background: rgba($text_minor, .3);
                        height: calc(100% - 60px);
                        right: 0;
                        top: 0;
                        position: absolute;
                        z-index: 55;
                        width: calc(100% - 60px);
                    }
                }

                &-bottom {
                    float: right;
                    margin-top: 160px;
                    margin-right: 215px;
                }

                @include bdown(sm) {
                    &-bottom {
                        display: none;
                    }

                    &-top {
                        margin: 0;
                        padding: 0;
                        width: 100%;

                        &:before {
                            width: 100%;
                            height: 100%;
                        }

                        img {
                            max-width: 100%;
                            width: 100%;
                        }
                    }
                }
            }
        }

        @include bdown(md) {
            .image-wrap {
                text-align: left;
            }
        }
    }

    &-left {
        p {
            color: $text_minor_second;
            font-family: $rubik;
            font-weight: $rubik-regular;
            @include fluid-font(20px);
            line-height: 1.5;
        }
    }

    @include bdown(sm) {
        .btn {
            margin: auto;
            display: flex;
        }
    }

    &-header {
        height: 130px;
        width: 100%;
        background: $blue_second_base;

        .content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;

            .title {
                &-1 {
                   color: $white;
                    font-family: $work-sans;
                    font-weight: 600;
                    @include fluid-font(36px);
                }

                &-2 {
                    color: $white;
                    margin-bottom: 0;
                    font-family: $rubik;
                    font-weight: $rubik-regular;
                    @include fluid-font(20px);

                }
            }

            .sticker {
                background: url(../../../assets/images/sticker.svg);
                background-size: cover;
                background-position: center;
                width: 200px;
                height: 200px;
                position: relative;
                margin-right: 20px;

                &-wrap {
                    display: flex;
                    align-items: center;
                }

                span {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    @include fluid-font(45px);
                    font-family: $work-sans;
                    font-weight: 600;
                    color: $blue_base;
                }
                //position: absolute;
            }
        }

        @include bdown(md) {
            padding: 20px 0;
            height: auto;

            .content {
                flex-direction: column;

                .title-wrap {
                    text-align: center;
                    margin-bottom: 40px;

                    .decorated:after {
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
            }
        }
    }
}

.about--block {
    h3 {
        color: $brown_base;
        @include fluid-font(18px);
        font-family: $rubik;
        font-weight: $rubik-medium;
    }

    h1 {
        color: $blue_second_base;
        @include fluid-font(36px);
        font-family: $work-sans;
        font-weight: 700;
        margin-top: 1rem;
    }

    h2 {
        color: $dark_blue_text;
        @include fluid-font(24px);
        font-family: $work-sans;
        font-weight: 500;
        margin-bottom: 20px;
    }

    p {
        color: $text_main;
        @include fluid-font(18px);
        font-family: $rubik;
        font-weight: $rubik-regular;
    }

    .btn {
        margin-top: 2rem;
    }
}

.in-numbers {
    color: $blue_text;
    background: $blue_base;
    background-size: cover;

    .inner {
        display: flex;
        justify-content: center;
        align-items: center;

        .content-icon {
            min-width: 70px;
            width: 70px;
            height: auto;
            margin-right: 10px;

            img {
                object-fit: cover;
            }
        }

        &.align-top {
            align-items: end;
        }

        i {
            @include font-count(50, 70);
            margin-right: 20px;
            color: $brown_base;

            &.blue-dark {
                color: $blue_second_base;
            }
        }

        @include bdown(md) {
            justify-content: left;
        }

        .content-block {
            min-width: 70%;
        }
    }

    .num-container {
        h3, h3 a {
            font-weight: 700;
            font-family: $work-sans;
            color: $blue_second_base;
            @include fluid-font(36px);
            margin-bottom: 0;
        }

        h5 {
            font-size: 17px;
            font-weight: 500;
            font-family: $work-sans;
            color: $blue_second_base;
            margin-bottom: 10px;
        }

        p {
            display: block;
            outline: none;
            @include fluid-font(18px);
            font-weight: 400;
            font-family: $roboto;
            color: $white;
            margin: 0;
        }

        &.dark {
            h3, h5, h3 a {
                color: $blue_base;
            }
        }

        span {
            display: block;
            font-family: inherit;
            outline: none;

            &:first-child {
                @include font-count(24, 32);
                // @include letter-spacing(52);
                font-weight: 700;
                font-family: $work-sans;
            }

            &:last-child {
                @include font-count(10, 17);
                line-height: 1.5;
                font-family: $work-sans;
            }
        }

        @include bdown(nm) {
            flex-wrap: wrap;

            .col {
                // flex: 1 0 33%;
                // max-width: 350px;
                // margin: auto;
                padding-left: 0;
                padding-right: 0;
                flex: 0 0 50%;
            }
        }
        @include bdown(xs) {

            .col {
                padding-left: 0;
                padding-right: 0;
                flex: 1 0 100%;
                text-align: center;
                .inner {
                    justify-content: center;
                }
            }
        }
    }
}

.divider {
    &-white {
        height: 20px;
        width: 100%;
        background: $white;
    }
}

.team {
    &--unit {
        p {
            font-family: $rubik;
            font-weight: $rubik-regular;
            color: $text_minor;
            @include fluid-font(19px);
        }
    }

    &--name-block {
        background: $brown_base;
        padding: 20px 40px;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
        margin-left: -40px;
        margin-top: 15px;
        margin-bottom: 30px;
        color: $white;

        .name {
            font-family: $work-sans;
            font-weight: 600;
            @include fluid-font(31px);
            margin-bottom: .5rem;
        }

        .position, p {
            font-family: $rubik;
            font-weight: $rubik-regular;
            @include fluid-font(23px);
        }

        p {
            @include fluid-font(19px);
            color: $text_main;
        }

        @include bdown(lg) {
            margin-left: -30px;
            padding: 20px 34px;
        }

        @include bdown(md) {
            border-radius: 0;
            padding: 10px 5.5%;
            margin-left: -5.5%;
            margin-right: -5.5%;
        }
    }
}

.backlink {
    display: inline-flex;
    font-size: 14px;
    font-family: $poppins;
    font-weight: 600;
    @include letter-spacing(200);

    &:before {
        content: "\e912";
        margin-right: .5rem;
        font-family: $icomoon;
        color: $blue_second_base;
    }

    color: $text_minor_second;

    &:hover {
        color: $brown_base;
    }
}

.person {
    &-photo {
        border-radius: 10px;

        @include bdown(md) {
            border-radius: 0;
        }
    }

    &-contacts {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .phone {
            color: $blue_text;
            font-family: $work-sans;
            font-weight: 600;
            font-size: 20px;
            margin-top: 1rem;

            i {
                margin-right: 10px;
                color: $blue_text;
            }
        }

        a {
            margin-top: 1rem;
            border-radius: 100%;
            width: 50px;
            height: 50px;
            background: $blue_text;
            display: inline-block;
            position: relative;
            margin-right: 20px;
            color: $blue_link;

            &:hover {
                background: $brown_base;
                color: $white;
            }

            i {
                top: 50%;
                left: 50%;
                position: absolute;
                transform: translate(-50%, -50%);
            }
        }
    }
}

.social-links {
    //padding-top: 1.5em;
    display: flex;

    li {
        flex: 0 1 auto;
        margin-right: 5px;
        position: relative;

        a {
            position: relative;
            border: 3px solid $blue_text;
            width: 46px;
            height: 46px;
            border-radius: 50%;

            &:before {
                position: absolute;
                display: block;
                font-size: 26px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: $blue_text;
                @include transition;
            }

            &:hover {
                border: 3px solid $brown_base;

                &:before {
                    color: $brown_base;
                }
            }
        }
    }
}

.contacts {
    &--wrap {
        padding: 0 50px;
    }

    &--item {
        position: relative;
        padding-bottom: .5rem !important;
        padding-left: 2.5rem !important;

        i {
            position: absolute;
            left: 0;
            font-size: 20px;
            top: 5px;
            color: $blue_text;

            &.icon-map-point {
                top: -4px;
                font-size: 40px;
            }

            &.icon-phone {
                top: 0;
                left: 7px;
                font-size: 25px;
                transform: rotate(-90deg);
            }
        }

        &:after {
            content: "";
            border-bottom: 2px solid #AACFD8;
            width: 70%;
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;

            @include bdown(md) {
                width: 40%;
            }
        }

        .element {
            font-family: $rubik;
            font-weight: $rubik-regular;
            margin-bottom: 5px;
            font-size: 16px;
            color: $text_minor;
        }
    }
}

.ltd {
    &--wrap {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    &--icon {
        position: absolute;
        left: 50%;
        top: -8%;
        transform: translate(-50%, -8%);
        width: 100px;
        height: 100px;
        background-size: cover !important;
        background-position: center !important;
    }

    &--card {
        background: $blue_bg;
        padding: 40px;
        margin-right: 20px;
        margin-bottom: 70px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1 0 48%;
        position: relative;
        max-width: 50%;

        p {
            font-family: $roboto;
            color: $text_main;
            @include fluid-font(17px);
            text-align: center;
        }

        h4 {
            @include fluid-font(30px);
            font-weight: 600;
        }

        @include bdown(md) {
            flex: 1 0 100%;
            max-width: 100%;
            margin-right: 0;
        }
    }
}

.card {
    &--wrap {
        column-count: 2;
        column-gap: 30px;

        section {
            page-break-inside: avoid;
            break-inside: avoid;
        }

        @include bdown(md) {
            column-count: 1;

            section {
                margin-bottom: 30px;
            }
        }
    }

    &--inner-row {
        padding: 0 60px;

        @include bdown(md) {
            padding: 0;
        }
    }

    &--icon-quotes {
        &:before {
            display: block;
            content: "";
            background: url(../../images/quotes.svg);
            background-size: cover;
            background-position: center;
            width: 75px;
            height: 60px;
            position: absolute;
            right: 56px;
            top: -30px;
        }
    }

    &--icon-question {
        .label-question {
            position: absolute;
            right: 90px;
            top: -40px;
            font-family: $rubik;
            font-weight: $rubik-medium;
            font-size: 16px;
            color: $brown_base;

            &:before {
                display: block;
                content: "";
                background: url(../../images/sticker-question-2.svg);
                background-size: cover;
                background-position: center;
                width: 110px;
                height: 120px;
                position: absolute;
                right: -120px;
                top: -40px;
            }
        }
    }

    &--text {
        padding: 40px;
        box-shadow: 0 0 11px 0 #ddd;
        position: relative;

        &.center {
            p {
                font-family: $roboto;
                color: $text_main;
                @include fluid-font(17px);
                text-align: center;
            }
        }

        &.story {
            font-family: $work-sans;
            font-weight: 500;
            color: $text_minor_second;
            line-height: 1.6;
            @include fluid-font(20px);

            p {
                font-family: $work-sans;
                font-weight: 500;
                color: $text_minor_second;
                line-height: 1.6;
                @include fluid-font(20px);
            }

            a {
                width: 100%;
                text-align: right;
                color: $brown_base;
                font-family: $work-sans;
                font-weight: 600;
                font-size: 11px;
                margin-bottom: 30px;
                outline: none;

                i {
                    margin-left: 10px;
                    font-size: 12px;
                }
            }

            h1, h2, h3, h4, h5, h6 {
                font-family: $work-sans;
                font-weight: 400;
                color: $dark_blue_text;
            }

            h2 {
                font-family: $work-sans;
                font-weight: 600;
                color: $dark_blue_text;
                @include fluid-font(22px);
                margin-bottom: 7px;
            }

            h3 {
                font-family: $rubik;
                font-weight: $rubik-regular;
                color: $text_minor_second;
                @include fluid-font(17px);
                margin: 0;
                margin-bottom: 40px;
            }
        }

        &.light-gray-text {
            p {
                color: $text_minor_second;
                font-family: $rubik;
                font-weight: $rubik-regular;
                @include fluid-font(20px)
            }
        }

        ol {
            padding-left: 1.5rem;

            li {
                line-height: 1.7;
                font-size: 18px;
                font-family: $work-sans;
                font-weight: 600;
                color: $blue_second_base;

            }
        }

        ul {
            margin-left: 60px !important;

            @include bdown(xs) {
                margin-left: 0 !important;
            }

            li {
                // margin-bottom: 30px;
                margin-bottom: .5rem;
                font-family: $work-sans;
                font-weight: 600;
                font-size: 18px;
                color: $blue_second_base;
                position: relative;
                padding-left: 20px;

                &:before {
                    content: "";
                    display: inline-block;
                    transform: none;
                    top: 6px;
                    background: $brown_base;
                    width: 9px;
                    height: 9px;
                    border-radius: 100%;
                    position: absolute;
                    left: 0;
                }
            }
        }

        &.big-field {
            padding-left: 100px;
            padding-right: 100px;

            @include bdown(md) {
                padding: 40px;
            }
        }

        a {
            font-size: 14px;

            i {
                margin-left: 7px;
            }
        }
    }
}

.sticker {
    &--question {
        background: url(../../../assets/images/sticker-question.svg);
        background-size: cover;
        background-position: center;
        width: 170px;
        height: 170px;
        position: absolute;
        left: 0;
        margin-right: 20px;
        z-index: 1;
    }

    &--block {
        display: flex;
        align-items: center;
        padding-left: 185px;
        height: 134px;
        position: relative;
        justify-content: space-between;

        &-small {
            height: auto;
            padding-bottom: 20px;
            padding-top: 20px;
            padding-right: 20px;

            .title {
                &-wrap {
                    width: 80%;
                }

                &-big {
                    @include font-count(30, 60);
                }

                &-small {
                    width: 100% !important;
                }
            }
        }

        @include bdown(nm) {
            .title {
                &-small {
                    width: 100% !important;
                }
            }
        }

        @include bdown(md) {
            padding: 20px 0;
            height: auto;
            flex-wrap: wrap;

            .btn {
                margin: auto;
                margin-top: 20px;
            }

            .title {
                &-wrap {
                    width: 100%;
                }

                &-big, &-small {
                    text-align: center;
                    width: 100% !important;
                    margin-right: 0;
                }
            }
        }

        .title {
            &-wrap {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
            }

            &-big {
                color: $white;
                font-family: $zilla;
                font-weight: 700;
                margin-right: 20px;
                @include fluid-font(70px);
            }

            &-small {
                color: $brown_base;
                font-family: $open-sans;
                font-weight: 500;
                margin-right: 20px;
                font-size: 20px;
                width: 300px;
            }
        }
    }
}

.extrabig-btn-wrap {
    .btn {
        margin-right: 40px;

        &:last-child {
            margin-right: 0;
        }
    }

    @include bdown(md) {
        text-align: center;

        .btn {
            margin-right: 0;
            margin-bottom: 20px;
            width: 51% !important;
        }
    }

    @include bdown(sm) {
        .btn {
            width: 90% !important;
        }
    }
}

.accordion {
    background: rgba($blue_second_base, .15);
    color: $blue_second_base;
    font-family: $work-sans;
    font-weight: 600;
    cursor: pointer;
    padding: 25px 85px 25px 30px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    @include fluid-font(30px);
    transition: .5s;
    position: relative;

    &--item {
        &-wrap {
            width: 100%;
            margin-bottom: 30px;
        }
    }

    &.active, &:hover {
        background-color: rgba($brown_base, .25);
    }

    &.active {
        color: $blue_base;
    }

    &:after {
        content: "+";
        color: $white;
        background: $brown_base;
        font-size: 40px;
        float: right;
        margin-left: 5px;
        width: 85px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    &.active:after {
        content: "-";
    }

    & + .panel {
        color: $text_minor;
        font-family: $rubik;
        font-weight: $rubik-regular;
        @include fluid-font(22px);
        background-color: rgba($brown_base, .25);
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-out;

        p {
            padding: 20px 30px;
        }
    }
}

.question-form, .faq-form {
    .label {
        font-family: $rubik;
        font-weight: $rubik-regular;
        color: $text_minor;
        @include fluid-font(26px);
    }
}

.faq-form {
    .label {
        color: $white;
    }
}

.image-card {
    &--wrap {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
    }

    &--image {
        width: 50%;
        z-index: 1;

        @include bdown(md) {
            width: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &--content {
        background: $white;
        padding: 60px;
        width: 50%;
        font-family: $roboto;
        font-weight: 400;
        @include fluid-font(18px);
        color: $text_main;
        box-shadow: 0 0 11px 0px #ddd;

        @include bdown(md) {
            width: 100%;
        }

        ul {
            li {
                line-height: 1.6;
                @include fluid-font(17px);
                font-family: $roboto;
                font-weight: $roboto-regular;
                color: $text_main;


                &:before {
                    content: "\e90c" !important;
                    font-family: "icomoon";
                    color: $blue_link;
                    font-size: 14px;
                    margin-right: 7px;
                }
            }
        }

        p {
            font-family: $roboto;
            font-weight: $roboto-regular;
            color: $text_main;
            @include fluid-font(17px);
        }
    }
}

article {
    margin-bottom: 30px;
    position: relative;
    @include bdown(md) {
        margin-bottom: 25px;
    }
    .article-header {
        @include transition;
        @include fluid-font(33px);
        color: $blue_second_base;
        font-weight: 700;
        text-align: left;
        margin-bottom: .5em;
        a {
            color: $blue_second_base;
            &:hover {
                color: $blue_base;
            }
        }
    }
    .article-inner {
        background-color: $blue_bg;
        padding: 3rem;
        .date {
            font-family: $rubik;
            font-weight: $rubik-regular;
            @include fluid-font(28px);
            color: $text_minor_second;
            margin-bottom: 2rem;
        }
        .excerpt, .article-text {
            font-family: $rubik;
            font-weight: $rubik-regular;
            @include font-count(14, 17);
            margin-top: 1.5em;
            line-height: 30px;
            color: $text_main;
            @include bdown(md) {
                max-width: none;
                margin-top: 2em;
            }
        }
        .blog-media {
            margin-bottom: 1rem;
        }
        .article-bottom {
            display: flex;
            justify-content: space-between;
            margin-top: 1rem;
            // ul {
            //     @extend %share-style4;
            //     display: inline-flex;
            // }
        }
        @include bup(md){
            padding: 3rem;
        }
        @include bdown(md){
            padding: 2rem;
            .article-bottom {
                display: block;
                .btn-container {
                    margin-bottom: 1rem;
                }
            }
        }

    }
    &.article-single {
        border: none;
        // margin-bottom: 0;
        padding: 0;
        .article-inner {
            position: relative;
            // .date {
            //     color: $brown;
            // }
            @include block-center;
            h2.single-article-header {
                @include fluid-font(33px);
                font-weight: 700;
                color: $blue_second_base;
            }
            .article-text {
                max-width: none;
                font-family: $roboto;
                font-weight: 400;

                p, ul, ol {
                    padding-bottom: 1.5em;
                }
            }
            .article-navigation {
                display: flex;
                justify-content: space-between;
                a {
                    flex: 0 1 45%;
                    position: relative;
                    font-weight: 700;
                    div:nth-of-type(1){
                        @include fluid-font(15px);
                        color: $blue_base;
                        margin-bottom: 1rem;
                    }
                    div:nth-of-type(2){
                        @include fluid-font(19px);
                    }
                    &:hover {
                        color: $blue_base;
                    }
                    &:after {
                        content: '\e912';
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        font-family: icomoon;
                        font-size: 1.5rem;
                        color: $blue_base;
                    }
                    &:first-child {
                        padding-left: 2rem;
                        &:after {
                            left: 0;
                        }
                    }
                    &:last-child {
                        padding-right: 2rem;
                        &:after {
                            transform: translateY(-50%) rotate(180deg);
                            right: 0;
                        }
                        @include bup(md){
                            text-align: right;
                        }
                    }
                }
                @include bdown(md){
                    flex-direction: column;
                    a {
                        margin-bottom: 2rem;
                        div:first-of-type{
                            margin-bottom: .5rem;
                        }
                    }
                }
            }
        }
        //.backlink-container {
        //    margin-bottom: 1rem;
        //
        //    a {
        //        @include fluid-font(17px);
        //        font-weight: 400;
        //        color: #2C2C2C;
        //        display: inline-flex;
        //        align-items: center;
        //
        //        &:before {
        //            color: $brown_base;
        //        }
        //        &:hover {
        //            color: $blue_second_base;
        //        }
        //    }
        //}
        @include bdown(md){
            padding-left: 5%;
            padding-right: 5%;
        }
    }
}

.pagination-container {
    text-align: center;
}
.pagination {
    display: inline-flex;
    align-items: center;
    padding-bottom: 20px;
    @include bdown(md) {
        display: flex;
        justify-content: space-between;
    }
    .pagination-list {
        flex-grow: 1;
        display: flex;
        justify-content: space-around;

        li {
            display: inline-block;
            margin-right: 7px;
            font-family: $poppins;
            font-weight: 400;

            a {
                color: #363636;
                background-color: $blue_bg;
                font-family: $poppins;
                text-align: center;
                padding: 12px 20px;
                font-weight: 300;
                // border: 1px solid #C8C8C8;
                border-radius: 3px;
                @include font-count(12,24);
                &:hover, &.active-pag {
                    background-color: $blue_second_base;
                    color: #fff;
                    // border-color: $blue_base;
                }
            }
        }
        @include bdown(md) {
            li {
                margin: 0 1px;
                a {
                    min-width: 0;
                    padding: 9px 15px;
                }
            }
        }
        @include bdown(xs) {
            li a {
                padding: 9px;
            }
        }
    }
    .pag-arrow {
        border-radius: 5px;
        color: $brown_base;
        font-size: 2em;
        // border: 2px solid transparent;
        flex-shrink: 0;
        display: flex;
        align-content: center;
        img {
            width: .8em;
            height: .8em;
        }
        &:hover {
            // color: $light-blue;
            img {
                filter: brightness(50%);
            }
        }
        &:first-of-type {
            margin-right: 20px;
        }
        &:last-of-type {
            margin-left: 20px;
        }
        @include bdown(md) {
            &:first-of-type {
                margin-right: 7px;
            }
            &:last-of-type {
                margin-left: 7px;
            }
        }
        @include bdown(xs) {
            min-width: 30px;
            padding: 5px;
            &:first-of-type {
                margin-right: 5px;
            }
            &:last-of-type {
                margin-left: 5px;
            }
        }
    }
    &.single-pagination {
        display: flex;
        .pagination-list {
            justify-content: space-between;
        }
    }
}

.text {
    &--header-brown {
        background: $brown_base;
        padding: 25px 50px;
        border-radius: 10px;
        margin-bottom: 25px;

        @include bdown(md) {
            padding: 15px 25px;
        }

        .title, .subtitle {
            color: $white;
        }

        .title {
            font-family: $work-sans;
            font-weight: 600;
            @include fluid-font(30px);
            margin-bottom: 4px;
        }

        .subtitle {
            font-family: $rubik;
            font-weight: $rubik-regular;
            @include fluid-font(23px);
        }
    }

    &--block {
        &-decorated {
            h1, h2, h3, h4, h5, ul, ol, p {
                padding: 0 2rem; 
                margin-bottom: 1em;
            }
            p {
                // padding: 25px 50px;
                font-family: $rubik;
                font-weight: $rubik-regular;
                //margin-bottom: 0;
                // margin-bottom: 30px;
                color: $text_minor;
                @include fluid-font(20px);

                // @include bdown(md) {
                //     padding: 15px 25px;
                // }
            }

            ul {
                // padding-left: 80px;
                // margin-bottom: 40px;
                padding-left: 2.5rem;


                @include bdown(md) {
                    padding-left: 1.5rem;
                }


                li {
                    font-family: $rubik;
                    font-weight: $rubik-regular;
                    @include fluid-font(19px);
                    line-height: 1.3;
                    position: relative;
                    padding-left: 20px;
                    margin-bottom: 15px;
                    color: $text_minor;

                    &:before {
                        content: "";
                        position: absolute;
                        border-radius: 50%;
                        left: 0;
                        top: 10px;
                        transform: translateY(-50%);
                        display: inline-block;
                        background-color: $brown_base;
                        width: 8px;
                        height: 8px;
                    }
                }
            }
        }

        &-link {
            font-family: $rubik;
            font-weight: 700;
            @include fluid-font(18px);
            color: $blue_second_base;
            position: relative;
            display: flex;
            float: right;
            padding-left: 50px;
            align-items: center;
            @include transition;

            @include bdown(md) {
                float: none;
            }

            i {
                color: $blue_link;
                width: 40px;
                height: 40px;
                background: $blue_text;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100%;
                position: absolute;
                left: 0;
                font-size: 16px;
            }

            &:hover {
                opacity: .8;
            }
        }
    }
}

.question--place {
    padding: 30px;
    padding-left: 150px;
    position: relative;

    .title, .subtitle {
        color: $white;
        font-family: $rubik;
    }

    .title {
        @include fluid-font(26px);
        font-weight: 700;
    }

    .subtitle {
        @include fluid-font(19px);
        font-weight: $rubik-medium;
        margin-top: 5px;
    }

    &:before {
        display: block;
        content: "";
        background: url(../../../assets/images/hands.svg) no-repeat;
        background-position: center;
        background-size: cover;
        position: absolute;
        left: 30px;
        top: 15px;
        width: 82px;
        height: 89px;
    }
}


.clickable--card {
    background: $white;
    border-radius: 10px;
    box-shadow: 1px 5px 15px 0 #eaeaea;
    cursor: pointer;
    height: 100%;
    @include transition;

    .pb-item--info {
        .pb-text {
            font-family: $rubik;
            font-weight: $rubik-regular;
            @include fluid-font(19px);
            color: $text_minor;
        }

        .pb-title {
            font-family: $work-sans;
            font-weight: 600;
            @include fluid-font(34px);
            color: $blue_second_base;
        }
    }

    &:hover {
        background: $brown_base;

        .pb-text, .pb-title, .pb-name, .pb-position {
            color: $white;
        }

        .sliding-content {
            top: 0;
        }

        .pb-item--container:before {
            height: 0;
        }
    }
}

.hidden-content {
    display: none;
}

.titles {
    &--wrap {
        h1, h2, h3, h4, h5, h6 {
            color: $text_main;
        }

        h1 {
            font-size: 32px;
        }

        h2 {
            font-size: 24px;
        }

        h3 {
            font-size: 18.72px;
        }

        h4 {
            font-size: 15px;
        }

        h5 {
            font-size: 13.28px;
        }

        h6 {
            font-size: 10.72px;
        }
    }
}

.accordion--item-wrap ul li:before {
    content: "";
    display: inline-block;
    transform: none;
    top: 6px;
    background: #a59873;
    width: 9px;
    height: 9px;
    border-radius: 100%;
    position: absolute;
    left: 0;
}

.accordion--item-wrap ul li {
    margin-bottom: 30px;
    font-family: 'Work Sans', sans-serif;
    font-weight: 600;
    font-size: 18px;
    color: #0C73B6;
    position: relative;
    padding-left: 20px;
}

.accordion--item-wrap ul {
    margin-left: 60px !important;
}