.btn {
  height: 60px;
  width: 235px;
  color: #fff;
  font-family: $rubik;
  font-weight: $rubik-medium;
  box-sizing: border-box;
  position: relative;
  padding: 10px 20px;
  @include fluid-font(17px);
  @include transition;
  line-height: normal;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  //@include letter-spacing(160);
  border: none;
  outline: none;
  border-radius: 5px;
  user-select: none;
  text-decoration: none;

  &.free-w {
    width: auto;
  }

  &.full-w {
    flex: 1;
  }

  .vertical-sep {
    height: 30px;
    margin-left: 12px;
    margin-right: 12px;

    &:after {
      border-right: 1px solid #fff;
    }
  }

  i {
    font-size: 22px;

    &.big {
      font-size: 30px;
    }
  }

  &-middle {
    height: 50px;
    width: 195px;
    @include fluid-font(16px);
  }

  &-brown {
    background: $brown_base;

    &:hover {
      background: $brown_base_dark;
    }
  }

  &-nopadding {
    width: auto;
    height: auto;
    padding: 0;
  }

  &-white {
    background: $white;
    color: $blue_second_base;
    font-family: $work-sans;
    font-size: 14px;
    font-weight: 600;

    i {
      font-size: 22px;
      margin-right: 10px;
    }
  }

  &.brown-border {
    border: 2px solid $brown_base;
  }

  &.brown-icon {
    i {
      color: $brown_base;
    }
  }

  &-blue {
    background: $blue_second_base;

    &:hover {
      background: $blue_second_dark;
    }
  }

  &-darkblue {
    background: $blue_base;

    &:hover {
      background: $blue_second_dark;
    }
  }

  &.big {
    height: 70px;
  }

  &.extra-big {
    height: 90px;
    width: 100%;
    @include fluid-font(26px);
  }

  &--icon-calc {
    padding-left: 80px;

    &:before {
      display: block;
      content: "";
      background: url(../../images/calc.svg);
      background-size: cover;
      background-position: center;
      width: 40px;
      height: 55px;
      position: absolute;
      left: 20px;
    }
  }

  &--icon-people {
    padding-left: 80px;

    &:before {
      display: block;
      content: "";
      background: url(../../images/people.svg);
      background-size: cover;
      background-position: center;
      width: 40px;
      height: 52px;
      position: absolute;
      left: 20px;
    }
  }

  &--refer-friend {
    padding-left: 80px;

    &:before {
      display: block;
      content: "";
      background: url(../../images/refer-friend.svg);
      background-size: cover;
      background-position: center;
      width: 66px;
      height: 50px;
      position: absolute;
      left: 20px;
    }
  }

  &--icon-man {
    padding-left: 80px;

    &:before {
      display: block;
      content: "";
      background: url(../../images/man.svg);
      background-size: cover;
      background-position: center;
      width: 50px;
      height: 50px;
      position: absolute;
      left: 20px;
    }
  }
}