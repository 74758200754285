@import "typography";
html {
    font-size: 18px;
    @include bdown(md){
        font-size: 14px;
    }
}
a[href],
button {
    &:not(.disabled):not(:disabled) {
        cursor: pointer;
    }
    &.disabled,
    &:disabled {
        cursor: default;
        pointer-events: none;
    }
    transition: all .3s ease-in-out;
}
body {
    line-height: 1.25;
    font-size: 1rem;
    font-family: $poppins;
    color: $text_minor;
    // background-color: #EDFAFF;
}

.title {
    &-small {
        font-size: 16px;
        font-family: $rubik;
        font-weight: $rubik-medium;
        text-align: center;
    }

    &-big {
        font-size: 36px;
        font-family: $work-sans;
        font-weight: 600;
        text-align: center;

        &.decorated {
            margin-bottom: 2rem !important;
        }
    }
}

.brown {
    color: $brown_base !important;
}
.blue {
    color: $blue_second_base !important;
}
.darkblue {
    color: $blue_base !important;
}
.darkblue-text {
    color: $dark_blue_text !important;
}
.white {
    color: #fff !important;
}
.light-gray-text {
    color: $text_minor;
}
.violet-bg,
.blue-bg {
    color: $white;
    h1, h2, h3, h4, h5, h6, p, ul {
        color: inherit;
        span {
            color: inherit;
        }
    }
}

.light-blue-bg {
    background-color: $light-blue;
}
.lightest-blue-bg {
    background: $blue_bg;
}

.dark-bg {
    background-color: #444;
}
.no-bg {
    background-image: none !important;
}
.underline {
    text-decoration: underline;
}
.all-caps {
    text-transform: uppercase;
}
.pr {
    position: relative;
}
.bold {
    font-weight: 700;
}
.no-pt {
    padding-top: 0;
}
.no-pb {
    padding-bottom: 0;
}
.no-p {
    padding-top: 0;
    padding-bottom: 0;
}
.hidden {
    display: none;

    &-desktop,
    &--desktop {
        @include bup(md){
            display: none !important;
        }
    }

    &-mobile,
    &--mobile {
        @include bdown(md){
            display: none !important;
        }
    }
}
.framed {
    border: .5rem solid $white;
    box-shadow: 0 0 1px #D1CFCF;
    img {
        display: block;
    }
}

.block-shadow {
    @extend %block-shadow-2;
    &--top{
        @extend %block-shadow-3;
    }
}
.double-shadow {
    @extend %double-shadow;
    position: relative;
    .white-bordered {
        border: .5rem solid #fff;
        box-shadow: 0 0 1px #CBCBCB;
        position: relative;
        z-index: 5;
        img {
            display: block;
            z-index: 5;
        }
    }
}
h2, .h2-style {
    @include fluid-font(48px);
    line-height: 1;
    position: relative;
    @include letter-spacing(20);
    font-family: $esteban;
    color: $violet;
    span {
        @include fluid-font(28px);
        font-family: $montserrat;
        font-weight: 600;
        @include letter-spacing(20);
        display: block;
        margin-top: 0.3rem;
        color: $blue;
        letter-spacing: normal;
    }
}
h3 {
    @include fluid-font(40px);
}

h4 {
    @include fluid-font(28px);
    color: $blue;
    font-family: $montserrat;
}
h5 {
    @include fluid-font(20px);
    font-family: $montserrat;
    color: $blue;
}
h6 {
    @include fluid-font(19px);
}
.text {
    font-family: $rubik;
    font-weight: $rubik-regular;
    color: $text_minor;

    &-left {
        text-align: left;
    }
    &-center {
        text-align: center;
    }
    &-right {
        text-align: right;
    }
}
@include bup(md){
    .responsive {
        &-text-right {
            text-align: right;
        }
        &-text-center {
            text-align: left;
        }
    }
}

@include bup(md){
    .r-text-left {
        text-align: left;
    }
    .r-text-center {
        text-align: center;
    }
    .r-text-right {
        text-align: right;
    }
}

.vertical-sep {
    &:after {
        content: '';
        height: 100%;
        display: block;
        border-right: 1px solid $blue_second_base;
    }
}

.flex {
    display: flex;

    &-right {
        justify-content: flex-end;
    }

    &-wrap {
        flex-wrap: wrap;
    }

    &--aic {
        align-items: center;
    }

    &--am {
        align-items: center;
    }
    &--auto {
        flex-basis: auto !important;
    }
}
p {
    line-height: 26px;
    margin-bottom: 1rem;
    &.subtitle {
        margin-bottom: .5em;
        text-align: center;
        @include letter-spacing(200);
        @include fluid-font(26px);
        color: $blue;
    }
    @include bdown(md) {
        line-height: 1.5;
    }
}

.partner-title {
    color: $blue_second_base;
    font-family: $work-sans;
    font-weight: 600;
    font-size: 38px;
}

.decorated {
    position: relative;
    margin-bottom: 4rem;
    font-family: $work-sans;
    font-weight: 700;

    &:after {
        content: "";
        display: block;
        border-bottom: 3px solid $brown_base;
        border-radius: 5px;
        width: 64px;
        left: calc(50% - 32px);
        top: 12px;
        position: relative;
    }

    &-small-mar {
        margin-bottom: .5rem;
    }

    &-left {
        &:after {
            left: 0;
        }
    }

    &-blue {
        &-text {
            color: $blue_second_base;
        }

        &:after {
            border-bottom: 3px solid $blue_second_base;
        }
    }

    &--line {
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        position: relative;

        &:after {
            width: 50px;
            height: 3px;
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            //background-color: $green;
        }
        &--orange:after {
            //background-color: $orange;
        }
        &--blue:after {
            background-color: $light-blue;
        }
        &--white:after {
            background-color: #fff;
        }
    }
}

.input {
    width: 100%;

    &-wrap {
        position: relative;
        flex: 1;

        .sep {
            height: 23px;
            position: absolute;
            top: 0;
            right: -40px;

            &:after {
                content: "";
                border-right: 1px solid rgba($color: #707070, $alpha: .5);
            }
        }

        i {
            position: absolute;
            transform: translateY(-50%) rotate(80deg);
            top: 50%;
            right: 7px;
            color: rgba($color: #374522, $alpha: .5);
            font-size: 20px;
        }
    }

    &-search {
        padding: 2px 30px 2px 0;
        border: 0;
        border-bottom: 1px solid rgba($color: $brown_base, $alpha: .5);
        font-size: 17px;
        font-family: $roboto;
        font-weight: $roboto-medium;
        color: $text_brown;
    }
}

.double-arrow {
    display: inline-flex;
    align-items: center;
    color: $blue;
    font-size: 15px;
    font-weight: 600;
    @include letter-spacing(50);

    &:hover{
        color: $light-blue;
    }
    &:after {
        content: "\e90a";
        padding-left: .5rem;
        font-family: $icomoon;
        display: inline-block;
    }
}


a[name^="eztoc"] {
    display: none !important;
}

section {
    .text-block.no-dot {
        ul {
            li {
                &:before {
                    content: none;
                }
            }
        }
    }
}
section a:not([class]) {
    color: $blue;
    &:hover {
        color: $violet;
    }
}

.link-more {
    position: absolute;
    top: 50%;
    transform: tramslateY(-50%);
    display: inline-flex;
    align-items: center;
    @include fluid-font(14px);
    color: $brown_base;
    font-weight: 600;
    @include letter-spacing(200);
    z-index: 10;
    &:after {
        font-family: $icomoon;
        content: "\e91e";
        display: inline-block;
        padding-left: .5rem;
        color: $light-violet;
        @include transition;
    }
    &:hover {
        color: $violet;
        &:after {
            color: inherit;
        }
    }
    @include bdown(md){
        position: static;
    }
    &.link-more--right {
        right: 15px;
    }
    &.link-more--left {
        left: 15px;
    }
    &--static {
        position: static;
    }
}
.arrowed-link {
    color: $blue;
    display: inline-flex;
    align-items: center;
    &:hover {
        color: $brown_base;
    }
    &:after {
        content: "\e91e";
        font-family: $icomoon;
        margin-left: .5rem;
    }
}
.back-link {
    color: #A2A0A0;
    &:before {
        color: $blue;
    }
    &:hover {
        color: $blue;
    }
}

.full-h {
    height: 100%;
}

.small-title {
    font-family: $rubik;
    font-weight: $rubik-medium;
    text-transform: uppercase;
    @include fluid-font(18px);
}

.text-block {
    font-family: $rubik;
    font-weight: $rubik-regular;
    color: $text_minor;
    @include fluid-font(19px);
}