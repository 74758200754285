@font-face {
  font-family: 'icomoon';
  src:  url('../../fonts/iconmoon/icomoon.eot?kzevco');
  src:  url('../../fonts/iconmoon/icomoon.eot?kzevco#iefix') format('embedded-opentype'),
    url('../../fonts/iconmoon/icomoon.ttf?kzevco') format('truetype'),
    url('../../fonts/iconmoon/icomoon.woff?kzevco') format('woff'),
    url('../../fonts/iconmoon/icomoon.svg?kzevco#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-plus:before {
  content: "\e91a";
}
.icon-phone-header:before {
  content: "\e919";
}
.icon-globe:before {
  content: "\e914";
}
.icon-envelope:before {
  content: "\e909";
}
.icon-hotel:before {
  content: "\e913";
}
.icon-phone:before {
  content: "\e915";
}
.icon-map-point:before {
  content: "\e918";
}
.icon-angle-double-right:before {
  content: "\e917";
}
.icon-angle-down:before {
  content: "\e916";
}
.icon-arrow-right:before {
  content: "\e911";
}
.icon-arrow-left:before {
  content: "\e912";
}
.icon-circle-left:before {
  content: "\e90f";
}
.icon-circle-right:before {
  content: "\e910";
}
.icon-menu:before {
  content: "\e90d";
}
.icon-close:before {
  content: "\e90e";
}
.icon-slide-left:before {
  content: "\e90b";
}
.icon-slide-right:before {
  content: "\e90c";
}
.icon-mail:before {
  content: "\e908";
}
.icon-mobile:before {
  content: "\e90a";
}
.icon-youtube:before {
  content: "\e902";
}
.icon-instagram:before {
  content: "\e903";
}
.icon-pinterest:before {
  content: "\e904";
}
.icon-linkedin:before {
  content: "\e905";
}
.icon-twitter:before {
  content: "\e906";
}
.icon-facebook:before {
  content: "\e907";
}
.icon-man:before {
  content: "\e900";
}
.icon-tablet:before {
  content: "\e901";
}