%title-block {
    .title-1 {
        @include font-count(20, 36);
        color: $white;
        font-weight: 600;
        @include letter-spacing(20);
        display: flex;
        margin-bottom: 1rem;
        font-family: $work-sans;
    }
    .title-2 {
        @include font-count(15, 24);
        color: $white;
        line-height: 1.3;
        font-family: $rubik;
        font-weight: $rubik-regular;
    }
    .title-3 {
        @include font-count(40, 77);
        display: inline-flex;
        align-items: center;
        color: $light-blue;
        margin-bottom: .3em;
        .sm {
            font-size: 16px;
        }
    }
    p {
        color: #fff;
        @include fluid-font(20px);
    }
}
%title-block-2 {
    .title-1 {
        color: #B4B4B4;
        @include fluid-font(48px);
        display: flex;
        margin-bottom: .5rem;
        font-family: $lora;
        font-style: italic;
    }
    .title-2 {
        @include fluid-font(39px);
        line-height: 1;
        color: #fff;
        font-family: $oswald;
        @include letter-spacing(20);
    }
    .title-3 {
        @include font-count(40, 77);
        display: inline-flex;
        align-items: center;
        color: $light-blue;
        margin-bottom: .3em;
        .sm {
            font-size: 16px;
        }
    }
    p {
        color: #fff;
        @include fluid-font(18px);
        font-weight: 500;
    }
}
%share-style1 {
    display: flex;
    padding-top: 0;
    padding-right: 0;
    align-items: center;
    a {
        font-size: 1.3em;
        height: 1.3em;
        width: 1.3em;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 1.2rem;
        //color: $green;
        &:hover {
            //color: $blue;
        }
    }
}
%share-style2 {
    .icon {
        width: 2rem;
        height: 2rem;
        font-size: 1.5em;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 5px;
        background-color: rgba($color: #ffffff, $alpha: .1);
        border-radius: 50%;
        &:before {
            font-size: .7em;
        }
        &:hover {
            color: #fff;
        }
        &-facebook {
            background-color: #3B5998;
        }
        &-youtube {
            background-color: #FF0000;
        }
        &-pinterest {
            background-color: #CB2027;
        }
        &-twitter {
            background-color: #7ED0E2;
        }
        &-linkedin {
            background-color: #486AAE;
        }
        &-instagram {
            background-color: #506897;
        }
    }
}
%share-style3 {
    display: flex;
    padding-top: 0;
    padding-right: 0;
    align-items: center;
    .ssc-title {
        color: $text_minor_second;
        margin-bottom: 0;
    }
    ul {
        a {
            font-size: 1em;
            height: 1.5em;
            width: 1.5em;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 5px;
        }
        .icon-facebook {
            background-color: #3B5998;
        }
        .icon-twitter {
            background-color: #7ED0E2;
        }
        .icon-linkedin {
            background-color: #486AAE;
        }
        .icon-envelope-o {
            background-color: #6F7173;
        }
    }
}
%share-style4 {
    a {
        @include fluid-font(36px);
        &:hover {
            filter: brightness(110%);
        }
        &.fa-facebook-f {
            color: #3B5998;
        }
        &.fa-twitter {
            color: #7ED0E2;
        }
        &.fa-link {
            color: #3B3B3B;
        }
    }
}
%default-form {
    position: relative;
    // ::placeholder {
    //     text-transform: uppercase;
    //     background-color: #EAE5DC;
    // }
    input, select, textarea {
        @include fluid-font(22px);
        color: $text_minor_second;
        font-family: $rubik;
        font-weight: $rubik-regular;
        background-color: $blue_bg;
        //border: 1px solid $blue;
        border: none;
    }

    label {
        font-weight: $rubik-regular;
        font-family: $rubik;
        @include fluid-font(18px);
        @include letter-spacing(20);
        color: $text_minor;
        display: block;
        text-align: left;
        padding-bottom: .2em;
        margin-bottom: .5em;
        position: relative;

        sup {
            color: red;
            @include fluid-font(24px);
            position: absolute;
            top: -7px;
            margin-left: 7px;
        }

        &.file_upload {
            display: inline-flex;
            align-items: flex-start;
            input[type=file]{
                position: absolute;
                visibility: hidden;
                opacity: 0;
                display: none;
            }

            mark {
                background-color: transparent;
                color: #fff;
                opacity: 1;
                margin-left: .5em;
                @include transition;
            }

            .btn {
                cursor: pointer;
                &.empty {
                    background-color: transparent;
                    color: #CBCBCB;
                    border: 1px solid #CBCBCB;
                }
                &:not(.empty):hover {
                    & + mark {
                        opacity: 0;
                    }
                }
            }
        }
    }

    input, textarea {
        width: 100%;
    }

    input {
        height: 2.63em;
        padding: 0 1.5em;
    }

    .select-wrapper {
        //height: 2.63rem;
        position: relative;
        @include fluid-font(19px);
        background-color: $brown_base;

        &:before {
            position: absolute;
            right: .2em;
            top: 50%;
            transform: translate(-40%, -50%);
            z-index: 10;
            pointer-events: none;
            content: "\e916";
            font-family: $icomoon;
            font-size: 2em;
            color: $white;
        }

        &:after {
            position: absolute;
            display: block;
            content: "";
            right: 0;
            top: 0;
            width: 1.5em;
            height: 100%;
            background-color: $brown_base;
            font-size: 2em;
            z-index: 5;
            pointer-events: none;
        }
    }

    select {
        height: 3.13rem;
        padding: 0 1.5em;
        width: 100%;
        border-radius: 0;
        border: none;
        -webkit-appearance: none;
        color: $text_minor_second;

        &:focus {
            outline: none;
        }
    }

    textarea {
        padding: 1.5em;
        // margin-bottom: 1em;
    }
    .row {
        padding-top: 0px;
        padding-bottom: 0px;
        .col {
            margin-bottom: 1em;
            
            &.fg-2 {
                flex-grow: 2;
            }
            &.fg-3 {
                flex-grow: 3;
            }
            &.fg-4 {
                flex-grow: 4;
            }
            &.file-upload-container {
                text-align: right;
                &.has-danger {
                    border: 1px solid #f00;
                    mark {
                        color: #f00;
                    }
                }
            }
            
            @include bdown(md) {
                margin-bottom: 15px;
            }
            p {
                text-align: left;
                margin-bottom: 0.5em;
                &.question {
                    @include font-count(24, 33);
                }
                &.c-info {
                    @include font-count(16, 24);
                    @include letter-spacing(100);
                }
            }
        }
    }

    .total-container {
        border-top: 1px solid $blue;
        color: $blue;
        text-align: left;
        padding-top: .5em;
        margin-top: 2em;
        margin-bottom: 2em;
        span {
            color: inherit;
            @include font-count(20, 33);
        }
        .total {
            text-transform: uppercase;
        }
    }
    .checkbox input,
    .radio input {
        display: none;
    }
    .checkbox label,
    .radio label {
        position: relative;
        padding-left: 3em;
        line-height: 2em;
        cursor: pointer;
        &:before {
            content: "";
            position: absolute;
            display: block;
            left: 0;
            top: 0;
            width: 2em;
            height: 2em;
            background-color: #eae5dc;
            border: 1px solid #9b918a;
        }
        &:after {
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            padding-left: .1em;
            content: "";
            @include font-count(20, 32);
            color: $blue;
        }
    }
    
    .checkbox input:checked + label,
    .radio input:checked + label{
        &:after {
            content: "\e90c";
            font-family: $icomoon;
        }
    }
    .inline-group {
        display: flex;
        justify-content: flex-start;
    }
    .captcha-inside {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        input {
            flex-basis: 50%;
        }
        @include bdown(md) {
            justify-content: flex-start;
            input {
                flex-basis: auto;
            }
        }
    }
}
%letter-form {
    .decorated {
        position: relative;
        &:after {
            width: 174px;
        }
    }
    h3 {
        @include letter-spacing(30);
        @include fluid-font(45px);
    }
    input, select, textarea {
        background-color: transparent;
        border: none;
        @include fluid-font(16px);
        width: 100%;
    }
    select, input {
        line-height: 2rem;
    }
    input {
        padding: 0 1rem;
        border-bottom: 1px solid #E3E0DB;
    }
    select {
        -webkit-appearance: none;
        border-bottom-color: transparent;
    }
    textarea {
        background-image: linear-gradient(180deg,hsla(0,0%,96.9%,0),hsla(0,0%,96.9%,0) calc(100% - 1px),#E3E0DB calc(100% - 1px), #E3E0DB);
        background-size: 100% 2.2em;
        line-height: 2.3em;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .form-inner {
        background-color: rgba($color: #EAE5DC, $alpha: .58);
        padding: 1rem;
    }
    .select-wrapper {
        position: relative;
        border-bottom: 1px solid #5F473F;
        &:before {
            position: absolute;
            right: .2em;
            top: 50%;
            transform: translateY(-50%);
            z-index: 10;
            pointer-events: none;
        }
    }
}

%aside-banner {
    background-color: $brown_base;
    color: #fff;
    padding: 2rem 10%;
    display: flex;
    justify-content: center;
    .inner {
        padding-left: 25%;
        position: relative;
        .btn {
            @include letter-spacing(160);
        }
        &:before {
            content: "\e93f";
            font-family: $icomoon;
            position: absolute;
            top: -.5rem;
            left: 0;
            color: #D3CABB;
            @include fluid-font(90px);
        }
        span {
            display: block;
            white-space: nowrap;
            @include fluid-font(30px);
            &:first-of-type {
                font-family: $lora;
            }
            &:nth--of-type(2) {
                font-weight: 600;
            }
        }
    }
}

%uldots {
    li {
        list-style-type: none;
        position: relative;
        padding-left: 1em;
        &:before {
            content: "";
            font-family: $icomoon;
            position: absolute;
            left: 0;
            top: .5em;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: $blue;
            display: inline-block;
        }
    }
}
%ulticks {
    li {
        list-style-type: none;
        position: relative;
        padding-left: 1em;
        &:before {
            content: "\e90c";
            font-family: $icomoon;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: $blue;
            display: inline-block;
        }
    }
}
%ularrows {
    li {
        list-style-type: none;
        position: relative;
        padding-left: 1em;
        &:before {
            content: "\e91b";
            font-family: $icomoon;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            display: inline-block;
        }
    }
}
%phone-block {
    //color: $orange;
    span {
        @include fluid-font(25px);
        font-weight: 600;
    }
    // font-weight: 900;
    &:before {
        margin-right: .5rem;
        border-radius: 50%;
        width: 35px;
        height: 35px;
        @include transition;
        background-color: $light-blue;
        color: #FAF6EC;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
    &:hover {
        color: $blue;
        &:before {
            background-color: $blue;
        }
    }
}


%block-shadow-1 {
    position: relative;
    &:after {
        content: "";
        background-image: url(../../../images/block-shadow.png);
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -2rem;
        height: 2rem;
        pointer-events: none;
    }
}
%block-shadow-2 {
    position: relative;
    &:after {
        content: "";
        background-image: url(../../../images/block-shadow.png);
        background-size: 100% 3.2rem;
        background-repeat: no-repeat;
        background-position: center top;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -3.2rem;
        height: 3.2rem;
        pointer-events: none;
    }
}
%block-shadow-3 {
    position: relative;
    &:after {
        content: "";
        background-image: url(../../../images/block-shadow.png);
        background-size: 100% 3.2rem;
        background-repeat: no-repeat;
        background-position: center top;
        position: absolute;
        width: 100%;
        left: 0;
        top: 0rem;
        height: 3.2rem;
        pointer-events: none;
    }
}
%double-shadow {
    position: relative;
    //&:after {
    //    content: "";
    //    background-image: url(../../../images/double-shadow.png);
    //    background-size: 100% 3.2rem;
    //    background-repeat: no-repeat;
    //    background-position: center top;
    //    position: absolute;
    //    width: 100%;
    //    left: 0;
    //    bottom: -1.4rem;
    //    height: 3.2rem;
    //}
    @include bdown(md){
        display: inline-block;
    }
}
%slider-arrow-style {
    .slick-arrow {
        position: absolute;
        color: rgba($white, .4);
        z-index: 100;
        top: 50%;
        transform: translateY(-50%);
        font-family: $icomoon;
        @include fluid-font(50px);
        @include transition;
    }

    .slick-prev {
        left: 0;
        padding: 1px .33em 1px 1em;
    }

    .slick-next {
        right: 0;
        padding: 1px 1em 1px 0.33em;
    }
}

%slider-arrow-style-2 {
    .slick-arrow {
        position: absolute;
        color: $blue_link;
        z-index: 100;
        top: 50%;
        transform: translateY(-50%);
        font-family: $icomoon;
        @include fluid-font(38px);
        @include transition;
        &:hover {
            color: $blue;
        }
    }
    .slick-prev {
        left: 0;
        padding: 1px .33em 1px 0.25em;
    }
    .slick-next {
        right: 0;
        padding: 1px .25em 1px 0.33em;
    }
}

%program-slider-arrow-style {
    .slick-arrow {
        position: absolute;
        color: rgba($blue_second_base, .8);
        z-index: 100;
        //top: 50%;
        bottom: -30px;
        //transform: translateX(-50%);
        font-family: $icomoon;
        @include fluid-font(30px);
        @include transition;

        &:hover {
            color: $brown_base;
        }
    }
    .slick-prev {
        left: calc(50% - 40px);
        //padding: 1px .33em 1px 1em;
    }
    .slick-next {
        right: calc(50% - 40px);
        //padding: 1px 1em 1px 0.33em;
    }
}

%stories-arrow-style {
    .slick-arrow {
        position: absolute;
        color: rgba($white, 1);
        z-index: 100;
        top: 50%;
        transform: translateY(-50%);
        font-family: $icomoon;
        @include fluid-font(50px);
        @include transition;

        &:hover {
            color: $blue_link
        }
    }

    .slick-prev {
        left: 0;
    }

    .slick-next {
        right: 0;
    }
}